//import _ from "lodash";

const opacityFull = 1;
const opacityHidden = 1;

const hideClusteredLayer = true;
/*
const getZoomScaleStops = function(factor) {
  return [
    [13, factor * 0],
    [14, factor * 0.01],
    [15, factor * 0.05],
    [17, factor * 0.3],
    [18.5, factor * 0.6],
    [20, factor * 1],
    [21.5, factor * 1.5]
  ];
};
*/
export default new (class {
  async createSignageLayer({
    parentThis,
    map,
    layerIdName,
    geoJson,
    appSettings,
    markerSections
  }) {
    //const layerIdName = "signage";
    //const pinScale = 1; //;2;

    let {
      hasPopup,
      clusterOptions,
      scaling,
      haveToolTip,
      hoverState,
      clusterSetup,
      filter,
      styles,
      showLabels,
      labelField
    } = appSettings.layerDefaults[layerIdName];

    let layerSitUnder = appSettings?.layersUnder[layerIdName];

    clusterSetup = { ...clusterSetup };
    if (markerSections) {
      let clusterProperties = {};
      for (const sectionKey in markerSections) {
        /*
        'clusterProperties': {
              // keep separate counts for each magnitude category in a cluster
              'points-of-interest': ['+', ['case', ["in",   ['get', 'section_slug'],"points-of-interest"], 1, 0]],
              'wadawurrung-stories': ['+', ['case', ["in",   ['get', 'section_slug'],"wadawurrung-stories"], 1, 0]],
              'food-and-shopping': ['+', ['case', ["in",   ['get', 'section_slug'],"food-and-shopping"], 1, 0]],
          }
        */
        clusterProperties[sectionKey] = [
          "+",
          ["case", ["in", ["get", "section_slug"], sectionKey], 1, 0]
        ];
      }
      clusterSetup.clusterProperties = clusterProperties;

      clusterSetup.clusterProperties.marker_indexs = [
        "concat",
        ["concat", ["get", "index"], ","]
      ];

      clusterSetup.clusterProperties.marker_point_name = [
        "concat",
        ["concat", ["get", "Point_Name"], ","]
      ];

      clusterSetup.clusterProperties.icon_ids = [
        "concat",
        ["concat", ["get", "icon_id"], ","]
      ];
    }

    if (geoJson?.data) {
      parentThis.dataSouceIds[layerIdName] = layerIdName;
      parentThis.filterableDataSouceIds[layerIdName] = layerIdName;

      map.addSource(layerIdName, {
        type: "geojson",
        data: geoJson.data,

        ...clusterSetup
      });
    }

    let layerId_clustered = `${layerIdName}_clustered_layer`;
    let layerId_clustered_lables = `${layerIdName}_clustered_lables`;
    let layerId_unclustered = `${layerIdName}_unclustered_layer`;
    let layerId_unclustered_lables = `${layerIdName}_unclustered_lables`;
    let layerId_unclustered_destination_labels = `${layerIdName}_unclustered_desintation_labels`;

    let layerId_unclustered_symbol = `${layerIdName}_unclustered_symbol_layer`;

    if (hideClusteredLayer !== true) {
      parentThis.pointLayerIds[layerId_unclustered] = layerId_unclustered;

      if (hoverState) {
        parentThis.hoverStatePointLayerIds[
          layerId_unclustered
        ] = layerId_unclustered;
      }

      if (haveToolTip) {
        parentThis.tooltipPointLayerIds[layerId_clustered] = layerId_clustered;

        parentThis.mobileClickTooltipPointLayerIds[
          layerId_clustered
        ] = layerId_clustered;
      }

      if (clusterOptions.useComboIcon === true) {
        parentThis.comboIconDataSoucesIds[layerIdName] = layerIdName;
      }

      if (hoverState && clusterOptions.useCircle) {
        parentThis.hoverStatePointLayerIds[
          layerId_clustered
        ] = layerId_clustered;
      }

      if (hasPopup != true) {
        parentThis.clusterLayersWithNoClick[
          layerId_clustered
        ] = layerId_clustered;
      }
      map.addLayer(
        {
          id: layerId_clustered,
          type: "circle",
          source: layerIdName,
          //    filter: ['has', 'point_count'],
          filter: filter.clustered_layer,
          minzoom: scaling.minZoom,

          paint: {
            ...styles?.clustered_paint,
            "circle-opacity": clusterOptions.useCircle
              ? {
                  stops: [
                    [scaling.clusterOpacityMinZoom, 0],
                    [scaling.clusterOpacityMaxZoom, 1]
                  ]
                }
              : 0.01,
            "circle-radius": 13
          }
        },
        layerSitUnder
      );

      //lables
      if (clusterOptions.useCircle) {
        parentThis.pointLayerIds[
          layerId_clustered_lables
        ] = layerId_clustered_lables;
        if (hoverState) {
          parentThis.hoverStatePointLayerIds[
            layerId_clustered_lables
          ] = layerId_clustered_lables;
        }
        if (haveToolTip) {
          parentThis.tooltipPointLayerIds[
            layerId_clustered_lables
          ] = layerId_clustered_lables;

          parentThis.mobileClickTooltipPointLayerIds[
            layerId_clustered_lables
          ] = layerId_clustered_lables;
        }
        map.addLayer(
          {
            id: layerId_clustered_lables,
            type: "symbol",
            source: layerIdName,
            minzoom: scaling.minZoom,
            filter: filter.clustered_layer,

            paint: {
              "text-color": appSettings.styles.markerText,
              "text-opacity": {
                stops: [
                  [scaling.clusterOpacityMinZoom, 0],
                  [scaling.clusterOpacityMaxZoom, 1]
                ]
              }
            },
            layout: {
              "icon-anchor": "bottom",
              "text-allow-overlap": true,
              "text-ignore-placement": true,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 15,
              "text-field": "+",
              "text-anchor": "center"
            }
          },
          layerSitUnder
        );
      }
    }

    let textField = showLabels
      ? {
          "text-field": [
            "get",
            labelField ? labelField : "Site_Marker_Number_Label"
          ]
        }
      : {};

    parentThis.pointLayerIds[layerId_unclustered] = layerId_unclustered;

    if (hoverState) {
      parentThis.hoverStatePointLayerIds[
        layerId_unclustered
      ] = layerId_unclustered;
    }
    if (haveToolTip) {
      parentThis.tooltipPointLayerIds[
        layerId_unclustered
      ] = layerId_unclustered;

      parentThis.mobileClickTooltipPointLayerIds[
        layerId_unclustered
      ] = layerId_unclustered;
    }

    let markerExtraFactor = 1.3;

    map.addLayer(
      {
        id: layerId_unclustered,
        type: "symbol",
        source: layerIdName,

        minzoom: scaling.minZoom,
        filter: filter.unclustered_layer,

        paint: {
          "icon-opacity": {
            stops: [
              [scaling.opacityMinZoom, opacityHidden],
              [scaling.opacityMaxZoom, opacityFull]
            ]
          }
        },
        layout: {
          "icon-anchor": [
            "case",
            ["==", ["get", "marker_type"], "destination"],
            "center", // Higher priority for "destination" markers
            "center" // Lower priority for other markers
          ],
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-image": ["get", "icon_id"],
          "symbol-sort-key": [
            "case",
            ["==", ["get", "marker_type"], "destination"],
            1, // Higher priority for "destination" markers
            0 // Lower priority for other markers
          ],

          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            [
              "case",
              ["==", ["get", "marker_type"], "destination"],
              0.01, // Size for "destination" at zoom level 10
              0.01 // Default size at zoom level 10
            ],
            3,
            [
              "case",
              ["==", ["get", "marker_type"], "destination"],
              0.14 * markerExtraFactor, // Size for "destination" at zoom level 14
              0.04 // Default size at zoom level 14
            ],
            6,
            [
              "case",
              ["==", ["get", "marker_type"], "destination"],
              0.3 * markerExtraFactor, // Size for "destination" at zoom level 18
              0.05 // Default size at zoom level 18
            ],
            8,
            [
              "case",
              ["==", ["get", "marker_type"], "destination"],
              0.35 * markerExtraFactor, // Size for "destination" at zoom level 18
              0.07 // Default size at zoom level 18
            ],

            10,
            [
              "case",
              ["==", ["get", "marker_type"], "destination"],
              0.4 * markerExtraFactor, // Size for "destination" at zoom level 18
              0.2 // Default size at zoom level 18
            ],
            12,
            [
              "case",
              ["==", ["get", "marker_type"], "destination"],
              0.45 * markerExtraFactor, // Size for "destination" at zoom level 18
              0.38 // Default size at zoom level 18
            ],

            14,
            [
              "case",
              ["==", ["get", "marker_type"], "destination"],
              0.5 * markerExtraFactor, // Size for "destination" at zoom level 18
              0.5 // Default size at zoom level 18
            ]
          ]
        }
      },
      layerSitUnder
    );

    map.addLayer(
      {
        id: layerId_unclustered_symbol,
        type: "symbol",
        source: layerIdName,

        minzoom: 11,
        filter: ["==", ["get", "marker_type"], "quest-hotel"],

        paint: {
          "icon-opacity": {
            stops: [
              [11, 0],
              [12, 1]
            ]
          }
        },
        layout: {
          "icon-anchor": "bottom-right",
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-image": "401", //id of dummy maker;
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            11,
            0.3,
            12,
            0.4,

            14,
            0.9,

            17,
            1.5
          ]
        }
      },
      layerId_unclustered
    );

    parentThis.hoverStatePointLayerIds[
      layerId_unclustered_destination_labels
    ] = layerId_unclustered_destination_labels;

    map.addLayer(
      {
        id: layerId_unclustered_destination_labels,
        type: "symbol",
        source: layerIdName,
        //minzoom: scaling.minZoom,
        //filter: filter.unclustered_layer,
        paint: {
          "text-color": "#525249", // Main text color (e.g., black)
          "text-halo-color": "#ffffff", // Halo (border) color
          "text-halo-width": 2, // Halo (border) width in pixels
          "text-halo-blur": 0.5, // Optional: adds a slight blur to the halo

          "text-opacity": {
            stops: [
              [2, 0],
              [3, 1],
              [9.8, 1],
              [10.4, 0]
            ]
          }
        },
        layout: {
          "text-allow-overlap": false,
          "text-ignore-placement": false,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-font": ["DM Sans 9pt Regular", "Arial Unicode MS Regular"],
          "text-size": {
            stops: [
              [2, 3],
              [4, 6],
              [6, 15],
              [8, 25]
            ]
          },
          "text-field": ["get", "custDestinationHeroLocationLabel"],
          "text-anchor": "left",
          "text-offset": {
            stops: [
              [2, [1.3, 0]],
              [5.8, [1.3, 0]],
              [6, [1, 0]],
              [8, [1, 0]],
              [10.4, [1.2, 0]]
            ]
          }

          // Offset to the right (1.5 units ~ 20px, depending on zoom level)
        }
      },
      layerSitUnder
    );

    if (showLabels) {
      parentThis.pointLayerIds[
        layerId_unclustered_lables
      ] = layerId_unclustered_lables;

      map.addLayer(
        {
          id: layerId_unclustered_lables,
          type: "symbol",
          source: layerIdName,
          minzoom: scaling.minZoom,
          filter: filter.unclustered_layer,

          paint: {
            "text-color": appSettings.styles.markerText,
            "text-opacity": {
              stops: [
                [scaling.opacityMinZoom, 0],
                [scaling.opacityMaxZoom, 1]
              ]
            }
          },
          layout: {
            "icon-anchor": "top",
            "text-allow-overlap": false,
            "text-ignore-placement": false,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": {
              stops: [
                [0, 1 / 3],
                [3, 14 / 3],
                [6, 30 / 3],
                [10, 40 / 3],
                [12, 45 / 3],
                [14, 50 / 3]
              ]
            },
            ...textField,
            "text-anchor": "top"
          }
        },
        layerSitUnder
      );
    }
  }
})();
