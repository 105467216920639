<template>
  <div
    v-show="!$vuetify.breakpoint.xsOnly && bookingLink"
    class="floatingBookingButton"
  >
    <v-btn
      style="height: 22px;"
      small
      color="questCream"
      class=" pa-0 ma-0 px-1"
      @click="openUrl()"
    >
      <font> Bookings</font>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "mvBookingButtons",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    trailTitle() {
      return this.trail?.title;
    },
    bookingLink() {
      return this.trail?.acf?.M_L_QUEST_BOOKING_LINK?.value?.url;
    }
  },
  methods: {
    openUrl() {
      if (this.bookingLink?.includes("http")) {
        window.open(this.bookingLink, "_blank"); // Opens the URL in a new tab/window
      }
    }
  }
};
</script>
<style>
.floatingBookingButton {
  position: absolute;
  top: 70px;
  left: 9px;
}

.isMobile .floatingBookingButton {
  position: absolute;
  top: 62px;
  left: 9px;
}
</style>
