<template>
  <div
    v-if="trailTitle"
    class="trailTitle"
    v-html="`Road Trip: ${trailTitle}`"
  ></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "mvTrailTitle",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    trailTitle() {
      return this.trail?.title;
    }
  },
  methods: {}
};
</script>
<style scopped>
.trailTitle {
  width: 170px;
  height: 36px;
  padding: 0 8px; /* Optional: Add some padding */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 18px; /* Half of the height to vertically center a single line */
  vertical-align: middle;
  text-align: left;
}
</style>
