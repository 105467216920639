// import Vue from 'vue'

const getIsDevEnviroment = () => {
  let isDev = false;
  let host = window.location.host;

  try {
    host = host.toLocaleLowerCase();

    if (host.includes("-dev") || host.includes("localhost")) {
      isDev = true;
    }
  } catch (error) {
    //do nothing;
  }

  return isDev;
};
/*
const clearUrlPath = function() {
  var blankUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: blankUrl }, "", blankUrl);
};
*/
const updatePath = state => {
  let { activeQueryStringMarkerId, activeQueryStringTrail } = state;

  let queryStringPerams = [];

  let queryString = "";

  if (activeQueryStringMarkerId) {
    queryStringPerams.push({ key: "id", value: activeQueryStringMarkerId });
  }

  if (activeQueryStringTrail) {
    queryStringPerams.push({ key: "trail", value: activeQueryStringTrail });
  }

  if (queryStringPerams.length) {
    queryString = "?";

    queryString += queryStringPerams
      .map(row => `${row.key}=${row.value}`)
      .join("&");
  }

  //let oldId = this.$route;
  //if (newId && newId !== oldId) {

  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    `${queryString}`;

  window.history.pushState({ path: newurl }, "", newurl);
};

const getters = {};
const state = {
  isDevEnviroment: getIsDevEnviroment(),
  baseurllocation: "/",
  //sidebarOpenInfo: false,
  sidebarSite: false,
  //openPopupSite: false,
  focusSite: false,
  siteList: {},
  activeQueryStringTrail: false,
  activeQueryStringMarkerId: false,
  navIsLoading: true,
  navFirstLoadTargetSite: false,
  screenQuerySections: [],
  screenQueryMarkers: []
};
const mutations = {
  UPDATE_SCREEN_QUERY_SECTIONS(state, newValue) {
    state.screenQuerySections = newValue;
  },
  UPDATE_SCREEN_QUERY_MARKERS(state, newValue) {
    state.screenQueryMarkers = newValue;
  },

  UPDATE_IS_LOADING(state, newValue) {
    state.navIsLoading = newValue;
  },
  UPDATE_FIRST_LOAD_TARGET_SITE(state, newValue) {
    state.navFirstLoadTargetSite = newValue;
  },
  UPDATE_SITE_LIST(state, newValue) {
    state.siteList = newValue;
  },
  UPDATE_SIDEBAR_SITE(state, newValue) {
    state.sidebarSite = newValue;

    if (state.sidebarSite === false) {
      //clearUrlPath();
      state.activeQueryStringMarkerId = false;
    } else {
      try {
        let properties = state.siteList[state.sidebarSite].properties;
        state.activeQueryStringMarkerId = properties.Site_Marker_Number;

        updatePath(state);
      } catch (error) {
        //do nothing!!!
      }
    }

    updatePath(state);
  },
  UPDATE_ACTIVE_TRAIL(state, newValue) {
    state.activeQueryStringTrail = newValue;
    updatePath(state);
  },
  UPDATE_FOCUS_SITE(state, newValue) {
    state.focusSite = newValue;
  }
};
const actions = {
  navUpdateIsLoading({ commit }) {
    commit("UPDATE_IS_LOADING", false);
  },
  navUpdateScreenQuerySections({ commit }, values) {
    commit("UPDATE_SCREEN_QUERY_SECTIONS", values);
  },
  navUpdateScreenQueryMarkers({ commit }, values) {
    commit("UPDATE_SCREEN_QUERY_MARKERS", values);
  },
  navUpdateFirstLoadTargetSite({ commit }, sites) {
    commit("UPDATE_FIRST_LOAD_TARGET_SITE", sites);
  },
  navUpdateSelectedTrail({ commit }, sites) {
    commit("UPDATE_ACTIVE_TRAIL", sites);
  },

  navUpdateSiteList({ commit }, sites) {
    commit("UPDATE_SITE_LIST", sites);
  },

  navUpdateSidebarSite({ commit }, focusSite) {
    commit("UPDATE_SIDEBAR_SITE", focusSite);
  },
  navUpdateFocusSite({ commit }, focusSite) {
    commit("UPDATE_FOCUS_SITE", focusSite);
    commit("UPDATE_SIDEBAR_SITE", focusSite);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
