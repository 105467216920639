const targets = [
  {
    postId: "markers",
    taxonomies: ["markersection", "marker_type"],
    sectionTaxonomies: "markersection",
    listTaxonomies: "markersection",
    iconTaxonomies: "marker_type",
    geoData: true
  },
  {
    postId: "artwork",
    taxonomies: ["artwork_section"],
    sectionTaxonomies: "artwork_section",
    listTaxonomies: "artwork_section",

    geoData: true,
    forceMarkType: "artwork"
  },

  {
    postId: "trails",
    taxonomies: ["state"],
    //sectionTaxonomies: ,
    //listTaxonomies: "artwork_section",

    geoData: false,
    isLinkedData: true
    //forceMarkType: "artwork"
  }

  /*
  {
    postId: "signage",
    taxonomies: ["signage_section"],
    sectionTaxonomies: "signage_section",
    listTaxonomies: "signage_section",
    geoData: true,
    forceMarkType: "signage"
  },
  {
    postId: "pages",
    taxonomies: [],
    //sectionTaxonomies: ,
    //listTaxonomies: "artwork_section",

    geoData: false,
    isLinkedData: true
    //forceMarkType: "artwork"
  },
  {
    postId: "posts",
    taxonomies: [],
    //sectionTaxonomies: ,
    //listTaxonomies: "artwork_section",

    geoData: false,
    isLinkedData: true
    //forceMarkType: "artwork"
  }*/
];

module.exports = {
  targets
};
