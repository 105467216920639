import _ from "lodash";
import { eventBus } from "../../../main";

const minZoomOpaciy = 16;
const maxZoomOpaciy = 17;

const minZoomScale = 12;
const maxZoomScale = 18;

const diableClusterIcon = false;
export default new (class {
  async addEvents({ parentThis, map, appSettings, mapboxgl, markerImages }) {
    const markers = {};
    let markersOnScreen = {};
    var zoomChange = _.debounce(
      function() {
        const currentZoom = map.getZoom();
        //set classes for opacity;
        let scale = 1;
        if (currentZoom < minZoomScale) {
          scale = 0;
        } else if (currentZoom > maxZoomScale) {
          scale = 1;
        } else {
          scale = (currentZoom - minZoomScale) / (maxZoomScale - minZoomScale);
        }
        let scaleClass = "markerScaleClass" + Math.round(scale * 10);

        //set classes for opacity;
        let opacity = 1;
        if (currentZoom < minZoomOpaciy) {
          opacity = 0;
        } else if (currentZoom > maxZoomOpaciy) {
          opacity = 1;
        } else {
          opacity =
            (currentZoom - minZoomOpaciy) / (maxZoomOpaciy - minZoomOpaciy);
        }
        let opacityClass = "markerOpaciyClass" + Math.round(opacity * 10);
        parentThis.$store.dispatch(
          "marker_SetmarkersScaleOpacityClass",
          `${opacityClass} ${scaleClass}`
        );
      },
      50,
      { maxWait: 60 }
    );

    map.on("zoom", () => {
      zoomChange();
    });

    zoomChange();

    if (appSettings.useMapClustering) {
      var doSome = _.debounce(
        function() {
          _.values(parentThis.comboIconDataSoucesIds).forEach(dataSource => {
            if (map.isSourceLoaded(dataSource)) {
              if (!markersOnScreen[dataSource]) {
                markersOnScreen[dataSource] = {};
              }
              if (!markers[dataSource]) {
                markers[dataSource] = {};
              }

              if (!diableClusterIcon) updateClusterMarkers(dataSource);
            }
          });
        },

        5,
        {
          // maxWait: 50
        }
      );

      map.on("render", () => {
        if (!map.isSourceLoaded("places")) return;

        if (!doSome) doSome();
      });

      const drawMarkerHtml = function(props) {
        let icon_ids = [];

        /*
        let labelHtml = "";
        if (props.markerLabel) {
          labelHtml = `<div class="clusterMarkerLabel">${props.markerLabel}</div>
          `;
        }
        ${labelHtml}
*/

        try {
          icon_ids = props.icon_ids
            .split(",")
            .filter(item => (item ? true : false));
          icon_ids = [...new Set(icon_ids)];
        } catch (error) {
          //do nothing;
        }

        let imageHtmlArary = [];
        let i = 1;

        icon_ids.forEach(imageId => {
          if (!markerImages?.[imageId]?.href) {
            //do nothing
          }

          if (imageHtmlArary.length < 4 && markerImages?.[imageId]?.marker) {
            imageHtmlArary.push(`
<div class="image${i}">
  <img src="${markerImages?.[imageId]?.marker}"  width="40px">
</div>
`);
          }
          i++;
        });

        //if only 1, both icons same, add 2nd
        icon_ids.forEach(imageId => {
          if (imageHtmlArary.length < 2 && markerImages?.[imageId]?.marker) {
            imageHtmlArary.push(`
<div class="image${i}">
  <img src="${markerImages?.[imageId]?.marker}"  width="40px">
</div>
`);
          }
          i++;
        });

        let html = `<div><div class="stackedMarker">

        ${imageHtmlArary.join(` `)}
        </div></div>`;

        const el = document.createElement("div");
        el.innerHTML = html;
        return el.firstChild;
      };

      const custClickEvent = function(/*setup*/) {
        //window.app.methods.testMethod(setup);
      };

      const updateClusterMarkers = function(dataSource) {
        const newMarkers = {};
        const features = map.querySourceFeatures(dataSource);

        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (const feature of features) {
          const coords = feature.geometry.coordinates;
          const props = feature.properties;
          if (!props.cluster) continue;
          const id = props.cluster_id;

          let marker = markers[dataSource][id];
          if (!marker) {
            let marker_indexs = [];
            try {
              marker_indexs = props.marker_indexs
                .split(",")
                .filter(item => (item ? true : false));
              marker_indexs = [...new Set(marker_indexs)];
            } catch (error) {
              //do nothing;
            }

            let markerLabelArray = [];
            try {
              markerLabelArray = marker_indexs.map(index => {
                if (
                  parentThis.sites[index]?.properties?.Site_Marker_Number_Label
                ) {
                  return parentThis.sites[index].properties
                    .Site_Marker_Number_Label;
                }
              });
            } catch (error) {
              //do nothing
            }

            props.markerLabel = markerLabelArray.slice(0, 3).join(", ");

            const el = drawMarkerHtml(props);
            marker = markers[dataSource][id] = new mapboxgl.Marker({
              element: el
            }).setLngLat(coords);

            marker.getElement().addEventListener("click", function(e) {
              let lngLat = map.unproject(e);
              let coordinates = [lngLat.lng, lngLat.lat];
              //parentThis.customMarkerClickEvent({ coordinates, marker_indexs });

              //window.app.methods.testMethod({ coordinates, marker_indexs });
              custClickEvent({ coordinates, marker_indexs });
            });
          }
          newMarkers[id] = marker;

          if (!markersOnScreen[dataSource][id]) {
            marker.addTo(map);
          }
        }
        // for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen[dataSource]) {
          if (!newMarkers[id]) markersOnScreen[dataSource][id].remove();
        }
        markersOnScreen[dataSource] = newMarkers;
      };
    }

    map.on("click", "places_clustered_layer", e => {
      let features = map.queryRenderedFeatures(e.point, {
        layers: ["places_clustered_layer"]
      });
      features = deDubeFeatures(features);
      const clusterId = features[0].properties.cluster_id;
      const point_count = features[0].properties.point_count;

      parentThis.clearAllPopups();

      map
        .getSource("places")
        .getClusterLeaves(clusterId, point_count, 0, (error, features) => {
          if (!error && features && features[0]) {
            //let coordinates = features[0].geometry.coordinates.slice();
            features = deDubeFeatures(features);
            let mappedMenuItems = features.map(item => item.properties);
            var indexs = [];
            mappedMenuItems.forEach(row => {
              if (!isNaN(row.marker_indexs))
                indexs = [...indexs, ...row.marker_indexs.split(",")];
              if (!isNaN(row.index)) indexs.push(row.index);
            });
            mappedMenuItems = [];
            [...new Set(indexs)].forEach(index => {
              if (parentThis.sites[index]?.properties)
                mappedMenuItems.push(parentThis?.sites[index]?.properties);
            });
            let coordinates = [e.lngLat.lng, e.lngLat.lat];
            parentThis.createStackedMenuPopup(coordinates, mappedMenuItems);
          }
        });
    });

    let deDubeFeatures = features => {
      let letFound = {};

      try {
        features = features.filter(feature => {
          if (!letFound[feature.id]) {
            letFound[feature.id] = true;
            return true;
          } else {
            return false;
          }
        });
      } catch (error) {
        //return false;
      }
      return features;
    };

    const addClickEvent = (event, isMainLayerClick) => {
      event.features = deDubeFeatures(event.features);

      let isClustered = event.features.length > 1 ? true : false;
      let coordinates = event.features[0].geometry.coordinates.slice();
      let properties = event.features[0].properties;
      parentThis.clearAllPopups();

      if (isClustered) {
        let mappedMenuItems = event.features.map(item => item.properties);
        parentThis.createStackedMenuPopup(coordinates, mappedMenuItems);
      } else {
        try {
          let { marker_type } = properties;

          if (marker_type == "destination") {
            let currentZoom = parentThis?.map.getZoom();
            var minZoom =
              parentThis?.appSettings?.zoomInOnClickIfSmaller[marker_type];
            if (currentZoom < minZoom) {
              //if user clicks on a destination marker, when very zoomed out, zoom in;
              //setTimeout(() => {

              parentThis.updatePaddingZoomForRemotePopup(
                coordinates,
                true,
                properties,
                "flyto"
              );
            }
          } else if (isMainLayerClick === true) {
            //console.log("places_unclustered_layer clickAct!!!");
            //console.log("not dest point, check padding");

            setTimeout(() => {
              parentThis.moveEndCheckPopupPositionGen();
            }, 300);
          }
        } catch (error) {
          //console.log("error!!", { error });
        }

        parentThis.createNormalPopup(coordinates, properties);
        parentThis.$store.dispatch("navUpdateSidebarSite", properties.index);

        //window.gtag("event", "markerClick", properties.Site_Marker_Number);
        window.mvAnalyticEvent(
          "map",
          "popupOpen",
          properties.Site_Marker_Number,
          false
        );
      }

      try {
        parentThis.lastMarkerSize = event.features[0].layer.layout["icon-size"];
      } catch (error) {
        //do nothing;
      }
    };

    map.on("click", "places_unclustered_desintation_labels", event => {
      addClickEvent(event);
    });

    map.on("click", "places_unclustered_symbol_layer", event => {
      addClickEvent(event);
    });

    map.on("click", "places_unclustered_layer", event => {
      addClickEvent(event, true);
    });

    _.values(parentThis.clusterLayersWithNoClick).forEach(layerId => {
      map.on("click", layerId, e => {
        map.flyTo({
          center: e.lngLat,
          zoom: map.getZoom() + 1
        });
      });
    });

    _.values(parentThis.geomClickLoadPopup).forEach(layerId => {
      map.on("click", layerId, e => {
        try {
          const [feature] = map.queryRenderedFeatures(e.point, {
            layers: [layerId]
          });

          let pointName = feature?.properties?.clickLoadSiteByTitle;

          if (pointName) {
            eventBus.$emit("nav_map_flyToOpenPopUpByName", pointName);
            //    goToPointByPointName(pointName) {
          }
        } catch (e) {
          //do ntohing;
        }
      });
    });

    parentThis.mobileClickTooltipTimeout = null;

    _.values(parentThis.mobileClickTooltipPointLayerIds).forEach(layerId => {
      map.on("click", layerId, e => {
        try {
          if (parentThis.$vuetify.breakpoint.mdAndDown) {
            try {
              parentThis.clearTooltip();
              if (parentThis?.mobileClickTooltipTimeout) {
                clearTimeout(parentThis.mobileClickTooltipTimeout);
              }
            } catch (error) {
              //do nothing
            }

            parentThis.createTooltip(e);
            parentThis.mobileClickTooltipTimeout = setTimeout(() => {
              try {
                parentThis.clearTooltip();
              } catch (error) {
                //do nothing;
              }
            }, 7 * 1000);
          }
        } catch (error) {
          //do nothing
        }
      });
    });

    _.values(parentThis.tooltipPointLayerIds).forEach(layerId => {
      map.on("mousemove", layerId, e => {
        try {
          parentThis.createTooltip(e);
        } catch (error) {
          //do nothing
        }
      });
      map.on("mouseleave", layerId, () => {
        parentThis.clearTooltip();
      });
    });

    _.values(parentThis.hoverStatePointLayerIds).forEach(layerId => {
      map.on("mouseenter", layerId, () => {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", layerId, () => {
        map.getCanvas().style.cursor = "";
      });
    });
  }
})();
