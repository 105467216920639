const theme = {
  /*
  primary: "#0B363D",
  secondary: "#4D4D4F",
  accent: "#fff", //link text
  error: "#4f8993", //item se
  ////secondary: "#394246lected
  info: "#51702d",
  success: "#061a35",
  //warning: "#00F"
  warning: "#fcfbf1", //marker text
  header: "#a3bba0",
  tabs: "#96a992",

  mediaButtonColor: "#0B363D",
  filterLegendButtonColor: "#0B363D",
  dialogActionButtonColor: "0B363D",
  logoTextColor: "#e9e3cd",
  filterButtonColor: "#D7DF23",

  mainMenuBarColor: "#3F8287",
  langButtonColor: "#0B363D",
  menuBarColor: "#0B363D",

  dialogToobarColor: "#3F8287",
  tabSliderColor: "#3F8287"

  */
};

const global = {
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoicXVlc3QtdG8tc2VlLWF1c3RyYWxpYSIsImEiOiJjbTM1aHJqMDkwYTJkMnFwdDVsbDBxMGl0In0.1M8cC4ZlSWVCTNpFlhMCEQ", //maptivate service;
      options: {
        //style: "mapbox://styles/mapbox/standard", //service account style

        //style: "mapbox://styles/quest-to-see-australia/cm3dni7iw001g01py2z8y8fnu", //service account style
        //style:"mapbox://styles/quest-to-see-australia/cm3dttiwi002401rc90uhco5z", //service account style

        style:
          "mapbox://styles/quest-to-see-australia/cm3jo6hpq007d01sq4rdw4zkx", //service account style

        center: [133.7751, -25.2744],
        maxZoom: 24,
        minZoom: 0,
        pitch: 10,
        bearing: 0,
        //bearing: 0,
        zoom: 3
      },
      bounds: [
        //[144.85036143131515, -37.8533280298009], // Southwest coordinates
        //[145.0615299795112, -37.712797163701886] // Northeast coordinates

        [40, -90],
        [230, 40]
      ],
      defaultSetup: {
        center: [133.7751, -25.2744],
        zoom: 3,
        zoomTab: 6,
        zoomMob: 6,
        pitch: 10,
        bearing: 0
        //bearing: 0
      },
      flyToBounds: [
        [112.9211, -44.36],
        [155.1092, -10.42]
      ],
      disableRotationAndBearing: false
    },

    layerDefaults: {
      signage: {
        labelField: "mv_label",

        scaling: {
          minZoom: 0,
          opacityMinZoom: 0,
          opacityMaxZoom: 1,

          clusterOpacityMinZoom: 0,
          clusterOpacityMaxZoom: 1,
          unclusterScale: 1
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 1, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        labelField: "mv_label",

        useCircle: false,
        scaling: {
          minZoom: 0,
          opacityMinZoom: 0,
          opacityMaxZoom: 1,

          clusterOpacityMinZoom: 0,
          clusterOpacityMaxZoom: 1,
          unclusterScale: 1
        },

        hasPopup: true,
        showLabels: false,
        hoverState: true,
        haveToolTip: true,
        styles: {
          unclustered_icon_size: {
            stops: [
              [6, 7.5 / 35],
              [7, 15 / 35]
            ]
          },
          unclustered_radius: {
            stops: [
              [6, 7.5],
              [7, 15]
            ]
          },
          clustered_radius: {
            stops: [
              [6, 10],
              [7, 20]
            ]
          }
        },

        clusterOptions: {
          useCircle: false,
          useComboIcon: false
        },
        clusterLabel: true,
        clusterSetup: {
          cluster: false,
          //clusterMaxZoom: 17,//setting this to null will make it work at 18, if i set it to 18 seems to break it.
          clusterRadius: 30,

          clusterProperties: {}
        },
        filterclusger: {
          unclustered_layer: ["!", ["has", "point_count"]],
          unclustered_layer_symbol: [
            "all",
            ["!has", "point_count"],
            ["==", "render_icons", true]
          ],
          //unclustered_layer: ["in", "section_slug","points-of-interest"],

          clustered_layer: ["has", "point_count"]
        },

        filter: {
          unclustered_layer: [
            "any",
            ["==", ["get", "marker_type"], "destination"], //alwasy show destination
            [">=", ["zoom"], 6] //show all after zoom 11
          ],

          clustered_layer: ["has", "point_count"]
        }
      }
    },
    zoomToOnSelect: 14.6,
    zoomToOnSelectSectionLookup: { destination: 14 },

    zoomInOnClickIfSmaller: { destination: 11 },

    boundFitDefaultMapPadding: { top: 90, bottom: 40, left: 25, right: 25 },
    boundFitDefaultMapPaddingMobile: {
      top: 90,
      bottom: 25,
      left: 17,
      right: 17
    },
    paddingRightForTrailPadding: 110,
    maxDistanceOfPointFromDestination: 15, //when zooming in on a destination point(all points in dest), dont zoom in point if its 50km from center;
    flyToDestBufferDestinationInM: 2000,
    flyToDestBufferOtherPointInM: 500,

    mainTabDark: false,
    listFilterTabDark: false,

    langButtonTextDark: true,

    titleImageLogo: true,
    titleImageLogoColor: true
  }
};

module.exports = {
  global,
  theme
};
