//import _ from "lodash";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  data: () => ({
    //layerState: false
    mainLayerToTrack: "places_unclustered_layer",
    onscreenFeatures: []
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    }),
    onScreenSections() {
      let values = {};
      this.onscreenFeatures.forEach(row => {
        let { section_slug } = row;
        values[section_slug] = section_slug;
      });

      return Object.keys(values);
    }
  },

  methods: {
    getVisiblePoints() {
      const features = this.map.queryRenderedFeatures({
        layers: [this.mainLayerToTrack] // Specify the layer ID you want to query
        //filter: ['==', '$type', 'Point'] // Filter for point features
      });
      this.onscreenFeatures = features.map(row => row.properties);

      this.$store.dispatch(
        "navUpdateScreenQuerySections",
        this.onscreenFeatures
      );
      //return features;
    },
    getVisiblePoints_debounced: _.debounce(function() {
      this.getVisiblePoints();
    }, 50),
    initMapScreenQueryFunction() {
      this.map.on("moveend", () => {
        this.getVisiblePoints_debounced();
      });
      this.map.on("move", () => {
        this.getVisiblePoints_debounced();
      });
    }
  },
  created() {},
  destroyed() {},
  mounted() {},

  watch: {
    onScreenSections: function(newValue) {
      this.$store.dispatch("navUpdateScreenQuerySections", newValue);
      //console.log("onScreenSections", newValue);
    }
  }
};
