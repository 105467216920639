import ToggleGeoButtonWrapper from "./../../ToggleGeoButtonWrapper.vue";

export default {
  data: () => ({
    //layerState: false
  }),

  computed: {},

  methods: {
    mountDynamicElements() {
      if (this.has.NavButton) {
        let target = "NavButton";

        let targetId = `${this.wrapperClass}_${target}Target`;

        let mountTarge = `.mvWpContentBlockWrapper.${this.wrapperClass} #${targetId}`;
        try {
          new ToggleGeoButtonWrapper({
            propsData: {
              vuetifyCopy: this.$vuetify,

              isReady: true,
              dialog: true
            }
          }).$mount(mountTarge);
        } catch (error) {
          //console.log("error", { error });
          //do nothink;
        }
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {},

  watch: {}
};
