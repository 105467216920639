<template>
  <v-btn
    dark
    title="Select a different trail"
    color="logoTextColor"
    class="trailSelectOptionsIcons"
    @click="openTrailSelector()"
  >
    <div class="custiconsWrapper ">
      <v-icon style="font-size: 15px;z-index: 5;" class="custicons"
        >mdi-chart-timeline-variant</v-icon
      >

      <v-icon style="font-size: 25px;z-index: 2;" class="custicons"
        >mdi-map</v-icon
      >
    </div>

    <font> Select A Road Trip</font>
  </v-btn>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "mvTrailSelectorButton",

  methods: {
    openTrailSelector() {
      eventBus.$emit("openTrailDialog");
    }
  }
};
</script>
<style>
.mvTitleBar img {
  padding: 2px;

  xxmax-height: 64px;
}

.mvTitleBar {
  max-height: 64px;
}

.mvTitleBar img.titleLogo {
  height: 55px;
  padding: 0;
}

.inline {
  float: left;
  height: 64px;
  xbackground: red;
  vertical-align: center;
}

.isMobile .mvTitleBar img.titleLogo {
  height: 35px;
  padding: 0;
}
</style>
