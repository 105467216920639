var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-card',{ref:"popupTop",staticClass:"pb-0 mvPopUpCard",attrs:{"elevation":"6","x_shaped":""}},[(_vm.properties.media && _vm.properties.media.length)?_c('v-btn',{staticClass:"topRightCloseBox",attrs:{"elevation":"0","fab":"","x-small":"","dark":"","color":"transparentBlackClose"},on:{"click":_vm.clearAllPopups}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.properties.media && _vm.properties.media.length)?_c('v-btn',{staticClass:"topLeftMediaButton",attrs:{"elevation":"0","fab":"","x-small":"","dark":"","color":"transparentBlackClose"},on:{"click":function($event){return _vm.loadImageGallery(_vm.properties.Site_Marker_Number)}}},[_c('v-icon',[_vm._v("mdi-arrow-expand-all")])],1):_vm._e(),_c('minGallery',{attrs:{"appSettings":_vm.appSettings,"properties":_vm.properties,"isPopup":true}}),(!(_vm.properties.media && _vm.properties.media.length))?_c('v-btn',{staticClass:"topRightCloseBox ",attrs:{"icon":"","color":"secondary","small":""},on:{"click":_vm.clearAllPopups}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_c('div',{staticClass:"titleBox  secondary--text mr-2"},[(_vm.properties.Point_Name)?_c('v-card-title',{key:"",staticClass:"pt-1 "},[_c('engAndTrans',{attrs:{"value":_vm.properties.Point_Name}})],1):_vm._e(),(
        _vm.properties.structuredData &&
          _vm.properties.structuredData.subtitle &&
          _vm.properties.structuredData.subtitle.M_ST_Point_Address &&
          _vm.properties.structuredData.subtitle.M_ST_Point_Address.value &&
          _vm.properties.marker_type != 'destination'
      )?_c('v-card-subtitle',{staticClass:"notranslate pb-1",attrs:{"translate":"no"}},[_vm._v(" "+_vm._s(_vm.properties.structuredData.subtitle.M_ST_Point_Address.value)+" ")]):_vm._e(),(
        _vm.properties.firstNationsName && _vm.properties.marker_type == 'destination'
      )?_c('v-card-subtitle',{staticClass:"notranslate pb-1",attrs:{"title":("First Nations Place Name: " + (_vm.properties.firstNationsName)),"translate":"no"}},[_vm._v(" "+_vm._s(_vm.properties.firstNationsName)+" ")]):_vm._e()],1),_c('PointDescriptionBody',{attrs:{"appSettings":_vm.appSettings,"functions":{ updateShowMore: _vm.updateShowMore, openSidebarRemote: _vm.openSidebarRemote },"type":"popup","Point_Description":_vm.Point_Description,"showMore":_vm.showMore}}),_c('extraInfoBlocks',{attrs:{"appSettings":_vm.appSettings,"properties":_vm.properties,"location":_vm.properties,"media":_vm.properties.media,"Point_Name":_vm.properties.Point_Name,"Site_Marker_Number":_vm.properties.Site_Marker_Number,"structuredData":_vm.properties.structuredData,"functions":{ openYoutube: _vm.openYoutube, setSound: _vm.setSound, getAudioDetails: _vm.getAudioDetails, pointSet: _vm.pointSet },"isPopup":true}}),(_vm.isDevEnviroment && _vm.properties.post_id)?_c('a',{staticClass:"ml-2",on:{"click":function($event){return _vm.editWpItem(_vm.properties.post_id)}}},[_vm._v(" cust wp edit ")]):_vm._e(),_c('v-btn',{staticClass:"pb-0 mb-0 pt-0 mt-0 moreInfoButton my-0 pb-0",attrs:{"elevation":"0","dark":"","color":'mediaButtonColor',"width":"100%"},on:{"click":function($event){return _vm.openSidebar(_vm.properties.index)}}},[_vm._v(" More Info ")]),_c('v-spacer')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }