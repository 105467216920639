<template>
  <div class="text-center">
    <v-dialog
      v-model="openShare"
      :width="700"
      transition="dialog-top-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="linkButton" v-show="false">
          Share
        </span>
      </template>

      <v-card style="overflow:hidden">
        <v-toolbar dark color="dialogToobarColor" elevation="1">
          <v-toolbar
            color="dialogToobarColor"
            elevation="1"
            v-if="false"
          ></v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>Share - {{ siteObject.title }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="openShare = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <br />
        <v-card-text class="">
          <v-text-field
            id="myInput"
            :value="pageUrl"
            label="Share URL"
            readonly
          ></v-text-field>

          <v-checkbox
            class="mb-0 pb-0"
            v-show="searchStringLocationPart"
            v-model="includeSearchQueryString"
            :label="`Share Selected Location: ${searchStringPointName}`"
          ></v-checkbox>
        </v-card-text>

        <v-card-text>
          <div class="d-flex flex-wrap">
            <v-btn
              @click="copyLink()"
              class="shareButton ma-1"
              outlined
              :color="linkBorderColor"
            >
              <v-icon>mdi-content-copy</v-icon>
              Copy Link
            </v-btn>

            <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :url="pageUrl"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
              :hashtags="sharing.hashtags"
            >
              <v-btn class="shareButton ma-1" outlined :color="linkBorderColor">
                <v-icon v-if="network.icon">{{ network.icon }}</v-icon>

                <img
                  class="socialLogo"
                  v-if="network.logo"
                  :src="`${network.logo}`"
                />

                {{ network.name }}
              </v-btn>
            </ShareNetwork>
          </div>
        </v-card-text>
        <center>
          <v-card-text>
            <qrcode-vue
              v-if="pageUrl"
              :value="pageUrl"
              :sizex="appSettings.mobDrawWidth"
              :size="200"
              level="H"
            ></qrcode-vue>
          </v-card-text>
        </center>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "./../../main";

import QrcodeVue from "qrcode.vue";

export default {
  name: "ShareDialog",
  components: { QrcodeVue },
  data() {
    return {
      openShare: false,
      searchString: window.location.search,
      searchStringSiteTarget: null,
      includeSearchQueryString: true,

      linkBorderColor: "secondary",

      networks: [
        {
          network: "email",
          name: "Email",
          icon: "mdi-email",
          color: "#333333"
        },

        {
          network: "facebook",
          name: "Facebook",
          icon: "mdi-facebook",
          color: "#1877f2"
        },

        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "mdi-whatsapp",
          color: "#25d366"
        },

        {
          network: "messenger",
          name: "Facebook Messenger",
          icon: "mdi-facebook",
          color: "#0084ff"
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "mdi-linkedin",
          color: "#007bb5"
        },

        {
          network: "twitter",
          name: "X",
          logo: "./assets/social/xlogo.png",
          color: "#1da1f2"
        }
        /*

//{ network: "messenger", name: "Messenger", icon: "mdi-facebook", color: "#0084ff" },

        { network: 'baidu', name: 'Baidu', icon: 'fas fah fa-lg fa-paw', color: '#2529d8' },
        { network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
        { network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
        { network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
        { network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
        { network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
        { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
        { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
        { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        { network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
        { network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
        { network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
        { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
        { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
        { network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
        { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
        { network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
        { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
        { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
        { network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
        { network: 'wordpress', name: 'Wordpress', icon: 'fab fah fa-lg fa-wordpress', color: '#21759b' },
        { network: 'xing', name: 'Xing', icon: 'fab fah fa-lg fa-xing', color: '#026466' },
        { network: 'yammer', name: 'Yammer', icon: 'fab fah fa-lg fa-yammer', color: '#0072c6' },
        { network: 'fakeblock', name: 'Custom Network', icon: 'fab fah fa-lg fa-vuejs', color: '#41b883' }*/
      ]
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,

      siteObject: state => state.global.siteObject,
      markerList: state => state.markers.markerList
    }),

    sites() {
      this.$store.dispatch("navUpdateSiteList", this.markerList.data.features);
      return this.markerList.data.features;
    },

    path() {
      if (window.location.host === "localhost" && false === true) {
        return `https://${window.location.host}`;
      } else {
        return `${this.siteObject.maptivateUrl}`;
      }
    },
    searchStringObj() {
      let searchStringObj = {};

      try {
        let params = new URLSearchParams(this.searchString);
        let entries = params.entries();
        searchStringObj = Object.fromEntries(entries); // {q: 'node', page: '2'}
      } catch (error) {
        //do nothing;
      }

      return searchStringObj;
    },
    pageUrl() {
      let searchString = "";

      if (
        this.includeSearchQueryString &&
        (this.searchStringObj.id || this.searchStringObj.id === 0) &&
        this.searchStringObj.trail
      ) {
        searchString = `/?id=${this.searchStringObj.id}&trail=${this.searchStringObj.trail}`;
      } else if (this.searchStringObj.trail) {
        searchString = `/?trail=${this.searchStringObj.trail}`;
      }

      let pageUrl = `${this.path}${searchString}`;

      return pageUrl;
    },
    searchStringPointName() {
      return this?.searchStringSiteTarget?.Point_Name;
    },
    searchStringLocationPart() {
      return this.searchString?.includes("id=");
    },
    sharing() {
      let hashtags = []; /*
      try {
        hashtags = [
          "ZooVictoria",
          "Zoo",
          this?.siteObject?.title?.replaceAll(" ", ""),
          this?.siteObject?.short?.replaceAll(" ", "")
        ];
      } catch (error) {
        //do nothing;
      }*/
      let setup = {
        title: `${this?.siteObject?.shareTitle}`,
        description: this.siteObject.shareDescription,
        quote: "......",
        hashtags: hashtags.join(",")
      };

      if (
        this.includeSearchQueryString &&
        this?.searchStringSiteTarget?.Point_Name
      ) {
        setup.title = `${this?.searchStringSiteTarget?.Point_Name} - ${this?.siteObject?.shareTitle}`.trim();
      }
      return setup;
    }
  },
  methods: {
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[[]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    copyLink() {
      var copyText = document.getElementById("myInput");
      // Select the text field
      try {
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
      } catch (error) {
        //do nothing;
      }

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      // Alert the copied text
    }
  },
  created() {
    eventBus.$on("openShareDialog", () => {
      window.mvAnalyticEvent("dialogOpen_Share");

      this.openShare = true;
    });
  },
  mounted() {
    setTimeout(() => {
      //this.openShare = true;
    }, 10);
  },

  watch: {
    openShare: function() {
      this.searchString = window.location.search;

      this.searchStringSiteTarget = null;

      if (this.searchString) {
        try {
          let queryStringSiteNumber = this.getParameterByName("id");

          let target = this.sites.filter(
            point =>
              point.properties.Site_Marker_Number === queryStringSiteNumber
          );

          if (target && target[0] && target[0].properties) {
            this.searchStringSiteTarget = target[0].properties;
          }
        } catch (error) {
          //do nothing;
        }
      }
    }
  }
};
</script>

<style>
.shareButton {
  text-transform: none !important;
}

.socialLogo {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
