<template>
  <div class="sidebarSiteInfoWrapper">
    <v-card
      v-if="properties && properties.Point_Name"
      style="border-radius:0"
      class=" pb-10 pb-12"
    >
      <v-btn
        elevation="0"
        v-if="properties.media && properties.media.length"
        @click="loadImageGallery(properties.Site_Marker_Number)"
        class="topLeftMediaButton ma-1"
        fab
        small
        dark
        color="transparentBlackClose"
      >
        <v-icon>mdi-arrow-expand-all</v-icon>
      </v-btn>

      <minGallery
        :appSettings="appSettings"
        :properties="properties"
        :isPopup="false"
      >
      </minGallery>

      <div class="sideBarTitle">
        <v-card-title
          class="secondary--text font-weight-regular text--darken-1"
          v-if="properties.Point_Name"
        >
          <font v-html="Site_Marker_Number_Label"> </font>
          <engAndTrans :value="properties.Point_Name" />
        </v-card-title>
        <v-card-subtitle
          v-if="
            false &&
              properties.section_slug &&
              categoryLookup[properties.section_slug] &&
              categoryLookup[properties.section_slug].name
          "
        >
          {{ categoryLookup[properties.section_slug].name }}
        </v-card-subtitle>

        <v-card-subtitle
          translate="no"
          class="notranslate"
          v-if="
            properties.structuredData &&
              properties.structuredData.subtitle &&
              properties.structuredData.subtitle.M_ST_Point_Address &&
              properties.structuredData.subtitle.M_ST_Point_Address.value
          "
        >
          {{ properties.structuredData.subtitle.M_ST_Point_Address.value }}
        </v-card-subtitle>

        <v-card-subtitle
          :title="`First Nations Place Name: ${properties.firstNationsName}`"
          translate="no"
          class="notranslate"
          v-if="properties.firstNationsName"
        >
          {{ properties.firstNationsName }}
        </v-card-subtitle>

        <extraInfoBlocks
          :location="location"
          :appSettings="appSettings"
          :properties="properties"
          :Point_Name="properties.Point_Name"
          :Site_Marker_Number="properties.Site_Marker_Number"
          :structuredData="properties.structuredData"
          :functions="{ pointSet, openYoutube, setSound, getAudioDetails }"
          :isJustSubtitles="true"
          :isPopup="false"
        />
      </div>

      <v-card-text
        class="py-0 xxgrey--text "
        v-for="item in detailsToShow"
        :key="item.field"
        v-show="properties[item.field]"
      >
        <span class="font-weight-medium text-uppercase headingText">
          {{ item.title }}:
        </span>

        <span class="" v-if="properties[item.field]">
          <a
            v-if="
              item.url &&
                properties[item.url] &&
                properties[item.url].includes('http')
            "
            :href="properties[item.url]"
            target="_blank"
            >{{ properties[item.field] }}</a
          >
          <font v-else>{{ properties[item.field] }}</font>
        </span>
      </v-card-text>

      <PointDescriptionBody
        :functions="{ updateShowMore }"
        type="siteInfo"
        :Point_Description="Point_Description"
        :showMore="showMore"
      />

      <extraInfoBlocks
        :appSettings="appSettings"
        :properties="properties"
        :Point_Name="properties.Point_Name"
        :Site_Marker_Number="properties.Site_Marker_Number"
        :structuredData="properties.structuredData"
        :location="location"
        :functions="{
          pointSet,
          openYoutube,
          setSound,
          getAudioDetails,
          loadDirections
        }"
        :media="properties.media"
        :isJustSubtitles="false"
        :isPopup="false"
        :eventsFormated="eventsFormated"
        :postId="properties.post_id"
      />

      <v-btn
        v-if="imageFiles && imageFiles.length"
        @click="loadImageGallery()"
        depressed
        :color="appSettings.mediaButtonColor"
        :width="appSettings.drawButtonWidth"
        class="ma-2 white--text"
      >
        <v-icon left>mdi-film</v-icon>
        View Gallery
      </v-btn>

      <v-card-text v-if="properties.linksAct && properties.linksAct.length">
        <span class="font-weight-medium text-uppercase headingText">
          LINKS:
        </span>
        <p
          v-for="link in properties.linksAct"
          :key="link.url"
          class="pa-0 ma-1 "
        >
          <a
            :href="link.url"
            target="_blank"
            style="text-decoration: underline!important;"
            class="primary--text"
          >
            {{ link.title }}
          </a>
        </p>
      </v-card-text>

      <v-card-text
        v-if="
          properties.downloadAssetsAct && properties.downloadAssetsAct.length
        "
      >
        <span class="font-weight-medium text-uppercase headingText">
          Documents:
        </span>
        <p
          v-for="link in properties.downloadAssetsAct"
          :key="link.url"
          class="pa-0 ma-1 "
        >
          <a
            :href="link.url"
            target="_blank"
            style="text-decoration: underline!important;"
            class="primary--text"
          >
            {{ link.title }}
          </a>
        </p>
      </v-card-text>

      <v-btn
        v-if="isDevEnviroment"
        @click="editWpItem(properties)"
        depressed
        :color="appSettings.mediaButtonColor"
        :width="buttonWidth"
        class="ma-2 white--text"
      >
        <v-icon left>mdi-pen</v-icon>
        Cust WP Edit
      </v-btn>
      <br />
      <br />
      <br />
      <br />
    </v-card>
    <div>
      <qrcode-vue
        v-show="appSettings.showQrCode"
        v-if="qrCodeUrl"
        :value="qrCodeUrl"
        :size="
          $vuetify.breakpoint.smAndUp
            ? appSettings.drawWidth
            : appSettings.mobDrawWidth
        "
        level="H"
      ></qrcode-vue>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";
import extraInfoBlocks from "./extraInfoBlocks.vue";
import QrcodeVue from "qrcode.vue";

import engAndTrans from "./../ui/engAndTrans.vue";
import PointDescriptionBody from "./../ui/PointDescriptionBody.vue";
import minGallery from "./../moreInfo/minGallery.vue";

export default {
  name: "SidebarSiteInfo",
  components: {
    PointDescriptionBody,
    engAndTrans,
    extraInfoBlocks,
    QrcodeVue,
    minGallery
  },
  data: () => ({
    showMore: false,
    justShowAllText: false,
    descriptionCropLength: 200,

    subTitleToShow: [
      /*{ field: "section_slug", title: "section_slug" }*/
    ],

    detailsToShow: [
      // { field: "section_slug", title: "section_slug" },
      //{ field: "Point_Number", title: "Site" },
      //{ field: "Point_Address", title: "Address" },
      { field: "Point_Artist", url: "Artist_URL", title: "Artist" }
      //      { field: "indexLabel", title: "Site Number" }
    ]
    //imageSize: { t: "200px", s: "160px", w: "140px" }
  }),
  computed: {
    ...mapState({
      siteObject: state => state.global.siteObject,

      appSettings: state => state.global.appSettings,
      categoryLookup: state => state.markers.categoryLookup,

      isDevEnviroment: state => state.navigation.isDevEnviroment,
      sidebarSite: state => state.navigation.sidebarSite,

      playingSiteName: state => state.media.playingSiteName,
      currentTrack: state => state.media.currentTrack,
      isPlaying: state => state.media.isPlaying,
      geoJson_artwork: state => state.markers.geoJson_artwork,
      geoJson_signage: state => state.markers.geoJson_signage,
      markerList: state => state.markers.markerList,
      eventsFormated: state => state.markers.eventsFormated
    }),

    buttonWidth() {
      if (this.isPopup === true) {
        return this.appSettings.popupButtonWidth;
      } else {
        let value = this.$vuetify.breakpoint.smAndUp
          ? this.appSettings.drawWidth
          : this.appSettings.mobDrawWidth;

        value = parseFloat(value) - 30;
        return `${value}px`;
      }
    },

    /*
    isPlayingThisTrack() {
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === this.properties.Point_Name
      ) {
        return true;
      } else {
        return false;
      }
    },*/
    sites() {
      return this.markerList.data.features;
    },
    pointSet() {
      return {
        geoJson_artwork: this.geoJson_artwork,
        geoJson_signage: this.geoJson_signage,
        markerList: this.markerList
      };
    },
    properties() {
      try {
        if (
          this.sites &&
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          //          console.log(this.sites[this.sidebarSite].properties);
          return this.sites[this.sidebarSite].properties;
        } else {
          //return null;
          throw { error: "error" };
        }
      } catch (error) {
        return null;
      }
    },
    Site_Marker_Number_Label() {
      if (!isNaN(this?.properties?.Site_Marker_Number_Label)) {
        return `${parseFloat(
          this.properties.Site_Marker_Number_Label
        )} - &nbsp;`;
      } else {
        return "";
      }
    },
    location() {
      let { Latitude, Longitude } = this.properties;
      return { Latitude, Longitude };
    },
    imageFiles() {
      let imageFiles = null;

      if (this.properties && this.properties.imageFiles) {
        try {
          imageFiles = this.properties.imageFiles;

          imageFiles = JSON.parse(imageFiles);
        } catch (error) {
          //do nothing
        }
      }

      return imageFiles;
    },
    Point_Description() {
      return this.properties?.content?.rendered;
    },
    /*
    Point_Description_CanCrop() {
      return !this.justShowAllText; //this.Point_Description.length > this.descriptionCropLength;
    },
*/
    qrCodeUrl() {
      if (this.properties && this.properties.Site_Marker_Number) {
        return (
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?id=${this.properties.Site_Marker_Number}`
        );
      } else {
        return false;
      }
    }
  },
  methods: {
    updateShowMore(value) {
      this.showMore = value ? value : false;
    },
    loadDirections(properties, type) {
      let { Latitude, Longitude } = properties;

      if (Latitude && Longitude) {
        if (type === "apple") {
          window.open(`http://maps.apple.com/?daddr=${Latitude},${Longitude}`);
        } else {
          window.open(
            `https://www.google.com/maps/dir/Current+Location/${Latitude},${Longitude}`
          );
        }
      }
    },
    editWpItem(properties) {
      //console.log("editWpItem", { post_id: properties.post_id });

      //let route = this.$router.resolve({ path: "/contact" });
      if (properties.post_id) {
        window.open(
          //`https://cms01.maptivateapp.com.au/sandbox01/wp-admin/post.php?post=${properties.post_id}&action=edit`
          `${this.siteObject.editPost}${properties.post_id}&action=edit`
        );
      }
    },

    loadImageGallery() {
      eventBus.$emit("loadGallery");
    },

    getAudioDetails(name, id) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isPlayingThisTrack = true;
      }

      if (
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isLoadedThisTrack = true;
      }

      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },
    openYoutube(youTubeAddress, Point_Name) {
      if (
        youTubeAddress.toLowerCase().includes("youtube") ||
        youTubeAddress.toLowerCase().includes("https://youtu")
      ) {
        this.$store.dispatch("mediaOpenYouTubeModal", {
          youTubeAddress,
          Point_Name
        });
      } else {
        window.open(youTubeAddress, "_blank");
      }
    },
    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    }
  },
  async mounted() {},
  watch: {
    sidebarSite: function(newValue) {
      this.showMore = false;
      if (newValue !== false || newValue === 0) {
        //this.$router.push({ name: "Home", params: { locationId: newValue } });
      }

      if (
        this.appSettings?.onMoreInfoLoadOnMobileExpanText == true &&
        this.$vuetify?.breakpoint?.mdAndDown
      ) {
        //is mobile so open all of the text;
        this.showMore = true;
      }
    }
  }
};
</script>
<style>
.Point_Description h1 {
  font-size: 14px;
  text-transform: uppercase !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.375rem !important;
  letter-spacing: 0.0071428571em !important;
  margin-top: 5px;
}

.Point_Description {
  max-height: 1000px;
  xtransition: all 1s;
}
.Point_Description.showLess {
  max-height: 120px;
  overflow: hidden;
  xtransition: all 1s;
}

.public-DraftStyleDefault-block {
  margin-bottom: 15px;
}

.Point_Description {
  position: relative;
}
.fade {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent, white);
}

.v-card__subtitle + .v-card__subtitle {
  margin-top: -30px;
}

@media only screen and (max-width: 959px) {
  .bottomSpace {
    margin-bottom: 100px;
  }
}
</style>
