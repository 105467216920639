<template>
  <div class="text-center ImageGallery">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      class="imageGalleryDialog"
      :width="$vuetify.breakpoint.mobile ? '95%' : '80%'"
      content-class="imageGalleryDialogAct"
    >
      <v-card class="">
        <v-toolbar dense dark :color="appSettings.mediaButtonColor">
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div class="xstreetViewDialog" ref="box" style="background: black;">
          <v-carousel
            class="fullHeightMax"
            v-if="imageFilesJoined && imageFilesJoined.length"
            hide-delimiters
            :show-arrows="imageFilesJoined.length > 1"
            height="auto"
            style=""
          >
            <v-carousel-item
              class="fullHeight"
              v-for="(item, i) in imageFilesJoined"
              :key="i"
            >
              <div class="fullHeight">
                <div class="fullHeight">
                  <center
                    style="position: relative;top: 50%;transform: translateY(-50%);"
                  >
                    <div>
                      <img
                        :src="item.src_gallery"
                        key=""
                        class="fullHeightMax"
                        style="
              
                  display: block;
                  max-width: 100%;
                  width: auto;
                  height: auto;"
                      />

                      <img
                        v-if="imageFilesJoined[i + 1]"
                        :src="imageFilesJoined[i + 1].src_gallery"
                        style="
                        opacity: 0.01;
                        display: block;
                        width: 1px;
                        height: 1px;"
                      />

                      <div class="imageCaption" v-if="item.caption">
                        {{ item.caption }}
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>

        <div
          v-for="(item, i) in media"
          :key="'x_' + i"
          style="height:0;width:0;overflow:hidden;"
        >
          <img
            :src="item.src_gallery"
            key=""
            class="fullHeightMax"
            style="
              
                  display: block;
                  
                  width: 1px;
                  height: 0;"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  props: {
    title: String,
    width: String,
    media: Array
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    }),
    imageFilesJoined() {
      let imageFilesJoined = [];
      if (this.media && this.media.length) {
        this.media.forEach(row => {
          imageFilesJoined.push({ ...row, type: "large" });
        });
      }
      return imageFilesJoined;
    }
  },
  created() {
    eventBus.$on("loadGallery", (/*value*/) => {
      this.dialog = true;
    });
  }
};
</script>

<style>
.imageCaption {
  background: #ffffffa8;
  color: black;
  bottom: 0;
  position: absolute;
  right: 0;
  padding: 2px 10px 0 10px;
}

.fullScreenDialog {
  background: black !important;
}

.fullHeight {
  width: 100%;
  height: calc(100vh - 101px);
}

.fullHeightMax {
  max-height: calc(100vh - 101px);
}

body div div.v-dialog.v-dialog--fullscreen {
  margin: 0;
}

@media only screen and (max-width: 1263px) {
  .fullHeight {
    width: 100%;
    height: calc(100vh - 150px) !important;
    max-height: calc(100vh - 150px) !important;
  }

  .fullHeightMax {
    max-height: calc(100vh - 150px) !important;
  }
}

@media only screen and (min-width: 601px) {
  body div div.v-dialog.imageGalleryDialogAct {
    margin-top: 55px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .fullHeight,
  .imageWrapperWrapper,
  .imageWrapper {
    width: 100%;
    height: calc(100vh - 250px) !important;
  }

  .fullHeightMax {
    max-height: calc(100vh - 250px) !important;
  }
}
</style>
