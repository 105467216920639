<template>
  <div class="siteKeyAct_wrapper  pb-10 pb-12">
    <div class="filterWrapper" xv-if="!currentCountryNotEnglish">
      <div class="filterBorder">
        <v-text-field
          :dark="appSettings.listFilterTabDark === true"
          background-color="filterButtonColor"
          solo
          v-model="wordFilter"
          label="List Filter"
          prepend-inner-icon="mdi-text-search-variant"
          clearable
        ></v-text-field>
      </div>
    </div>
    <div class="filterWrapperSpacer" style="height:50px"></div>

    <div class="siteListWrapper">
      <TrailItem />

      <v-list :class="[wordFilterClean ? 'filterRunning' : '']" class="pt-0">
        <v-list-item-group v-model="sidebarSiteActPlus" color="info">
          <v-list-group
            eager
            v-for="section in menuListItems"
            :key="section.key"
            xv-model="section.active"
            no-action
            v-show="
              !section.hideSection &&
                (!wordFilterClean || wordFilterCatList[section.key])
            "
            v-bind:class="[
              'catKeyAct_' + section.key,
              sectionClasses[section.key]
            ]"
            :aria-label="`${section.title}`"
          >
            <template v-slot:activator>
              <v-list-item-avatar
                size="40"
                color="questGold"
                class="sectionAvatar"
              >
              </v-list-item-avatar>

              <v-list-item-content eager>
                <v-list-item-title
                  :aria-label="`${section.title}`"
                  class="text-header text-wrap font-weight-medium font-weight-normal secondary--text text-uppercasexx"
                >
                  <engAndTrans v-if="false" :value="section.title" />

                  {{ section.title }}

                  <font
                    translate="no"
                    class="notranslate"
                    :title="
                      `First Nations place names: ${section.setup.firstNationsName}`
                    "
                    v-if="section.setup && section.setup.firstNationsName"
                  >
                    -
                    {{ section.setup.firstNationsName }}
                  </font>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="site in section.items">
              <v-list-item
                class="actListItem"
                eager
                v-show="
                  !wordFilterClean ||
                    (wordFilterClean &&
                      ((site.title &&
                        site.title.toLowerCase() &&
                        site.title.toLowerCase().includes(wordFilterClean)) ||
                        (currentCountryNotEnglish &&
                          translatedList[translationLanguageCode] &&
                          translatedList[translationLanguageCode][site.title] &&
                          translatedList[translationLanguageCode][site.title]
                            .toLowerCase()
                            .includes(wordFilterClean))))
                "
                color="info"
                :value="1000000 + site.properties.index"
                v-bind:class="[
                  'listItemAct',
                  'ml-0',
                  'siteKeyAct_' + site.properties.index,
                  sidebarSite === site.properties.index ? 'currentlyOpen' : ''
                ]"
                :key="site.key"
                @click.prevent="focusOnSite(site.properties.index)"
                @mouseenter="hoverOnListItem(site.properties.index)"
                v-on:dblclick="loadMoreInfo(site.properties.index)"
                :aria-label="`${site.title}`"
              >
                <v-list-item-avatar tile class="itemAvatar" size="28">
                  <img
                    :src="site.properties.list_image_obj.marker"
                    :aria-label="`${section.title}`"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="text-header text-wrap  secondary--text"
                  >
                    <engAndTrans :value="site.title" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list-item-group>
      </v-list>

      <center v-if="!sites.length || !trailDestinationSlugs.length">
        <br />
        <div class="ml-4 heading right">... no items or no destinations</div>
      </center>

      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
const simulateClick = function(elem) {
  // Create our event (with options)
  var evt = new MouseEvent("click", {
    bubbles: true,
    cancelable: true,
    view: window
  });
  // If cancelled, don't dispatch our event
  var canceled = !elem.dispatchEvent(evt);

  if (evt && canceled) {
    //do nothingk;
  }
};

const openTab = (section_slug, secondGo) => {
  var target = document.querySelector(
    `.catKeyAct_${section_slug} > div.v-list-group__header `
  );

  let value = target.getAttribute("aria-expanded");
  if (value === "true" || value === true) {
    value = true;
  } else {
    value = false;
    simulateClick(target);
  }
  if (!secondGo) {
    setTimeout(() => {
      openTab(section_slug, true);
    }, 200);
  }
};

import { mapState } from "vuex";
import { eventBus } from "../../main";

import engAndTrans from "./../ui/engAndTrans.vue";
import TrailItem from "./TrailItem.vue";

export default {
  name: "SiteList",
  props: {
    isOpen: Boolean,
    parentDrawer: Boolean
  },
  components: {
    engAndTrans,
    TrailItem
  },
  computed: {
    ...mapState({
      siteId: state => state.global.siteId,

      activeQueryStringMarkerId: state =>
        state.navigation.activeQueryStringMarkerId,
      sidebarSite: state => state.navigation.sidebarSite,
      markerList: state => state.markers.markerList,
      categoryLookup: state => state.markers.categoryLookup,
      listCategoryLookup: state => state.markers.categoryLookup, //listCategoryLookup
      markerImages: state => state.markers.markerImages,
      baseurllocation: state => state.navigation.baseurllocation,
      appSettings: state => state.global.appSettings,
      layers: state => state.filters.layers,
      applicationTypeFilters: state => state.filters.applicationTypeFilters,

      trailDestinationSlugs: state => state.filters.trailDestinationSlugs,

      //defaultLayers: state => state.filters.defaultLayers

      translationLanguageCode: state =>
        state.translator.translationLanguageCode,
      translatedList: state => state.translator.translatedList,
      screenQuerySections: state => state.navigation.screenQuerySections,

      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish
    }),

    singleScreenQuerySection() {
      if (this.screenQuerySections.length === 1) {
        return this.screenQuerySections[0];
      } else return null;
    },
    siteListSettings() {
      return this?.appSettings?.siteListSettings
        ? this?.appSettings?.siteListSettings
        : {};
    },
    wordFilterClean() {
      let wordFilter = "";

      //if (this.currentCountryNotEnglish !== true ) {
      try {
        if (
          this.wordFilter &&
          this.wordFilter?.length > 2 &&
          this.wordFilter.toLowerCase()
        ) {
          wordFilter = this.wordFilter.toLowerCase();
        }
      } catch (e) {
        //do nothingk
      }

      //console.log("wordFilterClean updated", wordFilter);
      //}
      return wordFilter;
    },

    listCategoriesLayers() {
      return Object.keys(this.listCategoryLookup);
    },

    sites() {
      let filters = this.layers;
      let options = this.applicationTypeFilters;

      let sites = this.markerList.data.features;

      //filter sites;
      sites
        //copyFilterCodeFromHere
        .forEach(feature => {
          let filterMatch = true;
          let layerMatch = true;

          try {
            let applyDirect =
              feature?.properties?.structuredData?.value?.M_C_YN_applyDirect
                ?.value == "Yes"
                ? true
                : false;
            filterMatch =
              options.includes("All") ||
              (options.includes("Participating") && applyDirect === false) ||
              (options.includes("Apply Direct") && applyDirect === true)
                ? true
                : false;
          } catch (error) {
            //do nothing!!
          }

          try {
            layerMatch = filters.includes(feature.properties.section_slug)
              ? true
              : false;
          } catch (error) {
            //do nothing!!
          }

          if (layerMatch && filterMatch) {
            feature.properties.hide = false;
            feature.geometry.coordinates = [
              feature.properties.Latitude,
              feature.properties.Longitude
            ];
          } else {
            feature.properties.hide = true;
            feature.geometry.coordinates = [0, 0];
          }
        });
      //copyFilterCodeToHere

      return sites.filter(feature => !feature?.properties?.hide);
    },
    origin() {
      return window.location.origin;
    },

    showMarkerLayer() {
      let layers = [];
      for (const key in this.categoryLookup) {
        const row = this.categoryLookup[key];
        //is main marker and is visiable
        if (row.section_type === "main_marker" && this.layers.includes(key)) {
          layers.push(key);
        }
      }
      return layers;
    },

    menuListItems() {
      let itemsObj = {};

      let markers = [];

      this.trailDestinationSlugs.forEach(section_slug => {
        let filteredMarkers = this.sites.filter(
          row => row?.properties?.section_slug === section_slug
        );

        markers = [...markers, ...filteredMarkers];
      });
      //markers = this.sites;

      markers.forEach(marker => {
        let { properties } = marker;

        let { section_slug, list_section_title } = properties;

        let hideSection = false;
        if (this?.hideSectionSlugs?.includes(section_slug)) {
          hideSection = true;
        }
        if (!itemsObj[section_slug]) {
          itemsObj[section_slug] = {
            title: list_section_title,
            key: section_slug,
            avatar: this.listCategoryLookup[section_slug]?.logo_image_obj?.href,
            setup: this.listCategoryLookup[section_slug],
            items: [],
            hideSection: hideSection
          };
        }

        itemsObj[section_slug].items.push({
          title: properties.Point_Name,
          key: properties.slug,
          properties: properties,
          listTitle: `${properties.Point_Name}`,
          index: properties.index
        });

        itemsObj[section_slug].items.forEach((row, index) => {
          row.order = index + 1;
        });
      });

      let count = 0;
      let items = 0;
      for (const section_slug in itemsObj) {
        itemsObj[section_slug].orderCount = count + items;

        items += itemsObj[section_slug].items.length;
        count++;
      }

      return itemsObj;
    },

    menuListItemsOrderCountLookup() {
      let lookup = {};

      for (const section_slug in this.menuListItems) {
        let value = this.menuListItems[section_slug];

        let { orderCount, key, title } = value;
        lookup[orderCount] = { key, title };
      }

      return lookup;
    },
    wordFilterCatList() {
      let lookupCount = {};

      for (const [section_slug, itemsObj] of Object.entries(
        this.menuListItems
      )) {
        lookupCount[section_slug] = false;

        if (this?.wordFilterClean?.length > 2) {
          lookupCount[section_slug] = false;

          itemsObj.items.forEach(site => {
            if (site.title.toLowerCase().includes(this.wordFilterClean)) {
              lookupCount[section_slug] = true;
            }

            try {
              if (
                this.translationLanguageCode &&
                this.currentCountryNotEnglish
              ) {
                let titleTranslated = this.translatedList?.[
                  this.translationLanguageCode
                ]?.[site.title];

                if (
                  titleTranslated.toLowerCase().includes(this.wordFilterClean)
                ) {
                  lookupCount[section_slug] = true;
                }
              }
            } catch (error) {
              //do nothing;
            }
          });
        }
      }

      return lookupCount;
    }
  },
  data: () => ({
    wordFilter: "",
    cancelScroll: null,
    sidebarSiteAct: false,
    sidebarSiteActPlus: false,
    holdScroll: false,
    hideSectionSlugs: ["fighting_extinction_speciesXX"],
    sectionClasses: {
      "african-river-trail": "shadow",
      "wildlife-hospital-track": "shadow",
      "main-track": "shadow",
      "woodlands-track": "shadow",
      growing_wild: "shadow",
      trail_elephants: "shadow",
      eat_play: "shadow"
    }
    //oldSlectedSite: false
  }),
  methods: {
    hoverOnListItem(index) {
      eventBus.$emit("markerEffects_bouncMarker", index);
    },

    resetFilters() {
      //this.$store.dispatch("filters_controlsUpdateLayers", this.defaultLayers);
      this.$store.dispatch("filters_resetLayers");
    },

    loadIntro() {
      eventBus.$emit("loadIntroPopup");
    },
    loadMoreInfo(index) {
      this.wordFilter = "";
      this.$store.dispatch("navUpdateSidebarSite", index);
    },
    focusOnSite(index) {
      this.wordFilter = "";
      eventBus.$emit("nav_SetTableMapView", "map");
      eventBus.$emit("nav_map_flyToOpenPopUp", index);
      setTimeout(() => {
        this.holdScroll = false;
      }, 200);
      try {
        if (this.sites[index]) {
          try {
            //window.gtag("event","sbClick",this.sites[index].properties.Site_Marker_Number);
            window.mvAnalyticEvent(
              "ui",
              "sbClick",
              this.sites[index].properties.Site_Marker_Number,
              false
            );
          } catch (error) {
            //do nothing
          }

          let coordinates;
          coordinates = this.sites[index].geometry.coordinates
            ? this.sites[index].geometry.coordinates
            : this.sites[index].properties.Longitude &&
              this.sites[index].properties.Latitude
            ? [
                this.sites[index].properties.Longitude,
                this.sites[index].properties.Latitude
              ]
            : false;

          if (coordinates) {
            this.$store.dispatch("navUpdateFocusSite", index);
          }
        }
      } catch (error) {
        //do nothing
      }
    },
    scrollToSite(targetSiteIndex) {
      this.wordFilter = "";
      if (targetSiteIndex === "wrapper") {
        //        active: false,
        let element = "siteKeyAct_wrapper";
        this.cancelScroll = this.$scrollTo(element);
      } else {
        try {
          let [targetSite] = this.sites.filter(
            site => site?.id === targetSiteIndex
          );

          let section_slug = targetSite.properties.section_slug;

          this.menuListItems[section_slug].active = true;

          openTab(section_slug);

          let element = ".catKeyAct_" + section_slug;
          this.sidebarSiteActPlus = 1000000 + targetSiteIndex;

          //let shouldScrollToItem = false;

          try {
            let item = this.menuListItems[section_slug].items.filter(
              row => row.index === targetSiteIndex
            )[0];

            let { order: itemOrder } = item;

            let sectionOrder =
              Object.keys(this.menuListItems).indexOf(section_slug) + 1;

            let itemHeight = 48;
            let uiHight = 150;

            let innerHeight = window.innerHeight;
            let currentItemOrder = itemOrder + sectionOrder;

            let totalItemsOnScreen = Math.round(
              (innerHeight - uiHight) / itemHeight
            );

            if (currentItemOrder > totalItemsOnScreen) {
              //shouldScrollToItem = true;
              element = ".siteKeyAct_" + targetSiteIndex;
            } else {
              element = "siteKeyAct_wrapper";
            }
          } catch (error) {
            //do nothing'
            console.error({ error });
          }

          setTimeout(() => {
            this.cancelScroll = this.$scrollTo(element);

            this.sidebarSiteActPlus = 1000000 + targetSiteIndex;
          }, 280);
        } catch (error) {
          //console.error(error, targetSiteIndex);
          console.error({ error });
        }
      }
      //to item
      /*
      if (!this.holdScroll && newValue != "false" && newValue != false) {
        let element = ".siteKeyAct_" + newValue;
        this.cancelScroll = this.$scrollTo(element);
      }
*/
    },
    scrollToTopIfNull() {
      setTimeout(() => {
        if (this.sidebarSite == false) {
          setTimeout(() => {
            if (this.sidebarSite == false) {
              setTimeout(() => {
                if (this.sidebarSite == false) {
                  this.scrollToSite("wrapper");
                }
              }, 50);
            }
          }, 50);
        }
      }, 50);
    }
  },
  created() {
    eventBus.$on("nav_siteList_scrollToTop", () => {
      this.scrollToSite("wrapper");
    });
    eventBus.$on("nav_filterRun", () => {
      this.wordFilter = "";
    });
  },
  watch: {
    parentDrawer(newValue) {
      if (newValue === true && this.sidebarSite == false) {
        this.scrollToTopIfNull();
      }
    },
    sidebarSiteActPlus(newValue) {
      //this code tracks the slected list items, if its a heading, and map is not over that area, map will pan to that point.
      const dontPanIfPopupOpen = false;
      if (
        !isNaN(newValue) &&
        newValue < 1000 &&
        ((!this.sidebarSite &&
          this.sidebarSite !== 0 &&
          !this.activeQueryStringMarkerId) ||
          !dontPanIfPopupOpen)
      ) {
        let target = this.menuListItemsOrderCountLookup[newValue];

        if (
          !this.singleScreenQuerySection ||
          this.singleScreenQuerySection != target.key
        ) {
          try {
            let targetItem = this.menuListItems[target.key].items[0];
            let sites = this.markerList.data.features.filter(row => {
              return row?.properties?.slug === targetItem.key;
            });

            if (sites[0]) {
              eventBus.$emit("flyToPoint", false, sites[0]);
              //Dont try to update pan to;
              eventBus.$emit("flyTo_clearTrackFitBounds");
            }
          } catch (e) {
            //do nothingk
          }
        }
      }

      if (this.currentCountryNotEnglish && !isNaN(newValue)) {
        //eventBus.$emit("mvTrans_updateNow");
      }
    },
    sidebarSiteAct(newValue) {
      this.sidebarSiteActPlus = 1000000 + newValue;
    },
    isOpen(newValue) {
      if (newValue === true) {
        if (this.sidebarSite == false) {
          this.scrollToTopIfNull();
        } else {
          this.scrollToSite(this.sidebarSiteAct);
        }
      }
    },
    sidebarSite(newValue /* oldValue*/) {
      this.sidebarSiteAct = newValue;

      if (newValue) {
        this.scrollToSite(newValue);
      } else {
        this.scrollToTopIfNull();
      }
    }
  },

  async mounted() {
    //this is to open first tab, if only one tab;
    setTimeout(() => {
      try {
        let sections = Object.keys(this.menuListItems);
        sections = sections.filter(row => {
          return !this.hideSectionSlugs.includes(row);
        });
        if (sections?.length === 1) {
          let [targetSection] = sections;
          if (this.sidebarSiteActPlus === false) {
            var target = document.querySelector(
              `.catKeyAct_${targetSection} > div.v-list-group__header `
            );
            simulateClick(target);
          }
        }
      } catch (error) {
        console.error(error);
        //do nothing;
      }
    }, 1000);
  }
};
</script>
<style lang="scss">
.text-header {
  font-size: 1.1em;
}

.currentlyOpen {
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.3;
}

.avatarSiteNumber {
  position: absolute;
  left: 0;
  top: 8px;
  width: 100%;
  //text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.26), 1px -1px 0 rgba(0, 0, 0, 0.26), -1px 1px 0 rgba(0, 0, 0, 0.26), 1px 1px 0 rgba(0, 0, 0, 0.26);
}

.sectionAvatar {
  color: white;
}

.itemAvatar {
  margin: 0;
}

.v-application--is-ltr
  .siteListWrapper
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item.actListItem {
  padding-left: 26px;

  .v-list-item__content {
    padding: 6px 0;
  }
  .text-header {
    font-size: 0.95em;
  }
}

.v-application--is-ltr
  .siteListWrapper
  .v-list-item__action:last-of-type:not(:only-child),
.v-application--is-ltr
  .siteListWrapper
  .v-list-item__avatar:last-of-type:not(:only-child),
.v-application--is-ltr
  .siteListWrapper
  .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: -16px;
}

.filterWrapper {
  margin-bottom: -42px;
  margin-left: -2px;
  margin-right: -2px;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.filterBorder {
  margin-right: -10px;
}

.listItemAct:last-child {
  margin-bottom: 12px;
}

.filterRunning .v-list-group__items {
  display: block !important;
}
.filterRunning .v-list-item__icon.v-list-group__header__append-icon i {
  display: none !important;
}

.v-avatar.roundedSquare {
  border-radius: 35%;
}

.listItemAct {
  min-height: 42px;
  height: auto;
}

.currentCountryNotEnglish .listItemAct {
  height: auto;
}

.currentCountryNotEnglish .listItemAct v-list-item__content {
  padding: 0;
}
.shadow .sectionAvatar > img {
  -webkit-filter: drop-shadow(1px 1px 0 rgba(255, 255, 255, 0.1))
    drop-shadow(-1px -1px 0 rgba(255, 255, 255, 0.1))
    drop-shadow(1px -1px 0 rgba(255, 255, 255, 0.1))
    drop-shadow(-1px 1px 0 rgba(255, 255, 255, 0.1));
}
.catKeyAct_fighting_extinction_species .sectionAvatar > img {
  -webkit-filter: none;
}
</style>
