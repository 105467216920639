<template>
  <div class="mapWrapper" ref="mapWrapper">
    <div id="currentZoomDev" v-if="isDevEnviroment">
      {{ currentZoom }}
    </div>
    <mapbox
      :access-token="appSettings.map.accessToken"
      :map-options="{ ...options, ...appSettings.map.options }"
      @map-load="loaded"
      @map-init="initialized"
      :nav-control="{
        show: true,
        position: 'top-left'
      }"
      :geolocate-control="{
        show: true,
        position: 'top-left',
        options: {
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true,
          showUserHeading: true
        }
      }"
      :scale-control="{
        show: false,
        position: 'top-left'
      }"
      :fullscreen-control="{
        show: false,
        position: 'top-left'
      }"
    />
  </div>
</template>

<script>
//cluster ajsdflj https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

import { mapState } from "vuex";
import { eventBus } from "../../main";

import Mapbox from "mapbox-gl-vue";
//import mapboxgl from "mapbox-gl";
import genericMapServices from "./services/genericMapServices.js";

import markerService from "./services/markerService.js";
import artworkAssetService from "./services/artworkAssetService.js";

import signageMarkerLayerService from "./services/signageMarkerLayerService.js";
import circleSignageMarkerLayerService from "./services/circleSignageMarkerLayerService.js";

//import animationService from "./services/animationService.js";
//import geoJsonLineGeomService from "./services/geom/geoJsonLineGeomService.js";
import tempGeoJsonLineGeomService from "./services/geom/tempGeoJsonLineGeomService.js";
//import customArrowWorkflow from "./services/customArrowWorkflow.js";

import mapEventsService from "./services/mapEventsService.js";

import layerFilterMixin from "./mixins/layer-filter-mixin";
import markerMenuMixin from "./mixins/marker-menu-mixin";
import markerEffectsMixin from "./mixins/marker-effects-mixin";
import tooltipMixin from "./mixins/tooltip-mixin";
import mediaEventsMixin from "./mixins/media-events-mixin";
import shareMixin from "./mixins/share-mixin";

import mapFlytoMixin from "./mixins/map-flyto-mixin";

import screenQueryMixin from "./mixins/screen-query-mixin";

import mapTrailMixin from "./mixins/map-trail-mixin";
import mapHeightMixin from "./mixins/map-height-mixin";

export default {
  components: { Mapbox },
  mixins: [
    mapHeightMixin,
    mapFlytoMixin,
    mapTrailMixin,
    shareMixin,
    layerFilterMixin,
    markerMenuMixin,
    markerEffectsMixin,
    tooltipMixin,
    mediaEventsMixin,
    screenQueryMixin
  ],
  name: "Map",
  computed: {
    ...mapState({
      isDevEnviroment: state => state.navigation.isDevEnviroment,

      //global:
      appSettings: state => state.global.appSettings,
      markerSections: state => state.markers.sections,
      geoJson_artwork: state => state.markers.geoJson_artwork,
      geoJson_signage: state => state.markers.geoJson_signage,
      categoryLookup: state => state.markers.categoryLookup,
      markerImages: state => state.markers.markerImages,
      playingSiteName: state => state.media.playingSiteName,
      currentTrack: state => state.media.currentTrack,
      isPlaying: state => state.media.isPlaying,

      //filters
      trailDestinationSlugs: state => state.filters.trailDestinationSlugs,

      //markers:
      stackedMarkers: state => state.markers.stackedMarkers,
      markerList: state => state.markers.markerList,

      //sites:
      baseurllocation: state => state.navigation.baseurllocation,
      layers: state => state.filters.layers
    }),
    sites() {
      this.$store.dispatch("navUpdateSiteList", this.markerList.data.features);
      return this.markerList.data.features;
    }
  },

  props: {
    drawer: Boolean
  },
  data: () => ({
    currentZoom: 0,
    lastMarkerSize: null,
    toolTipAct: null,
    useCluster: false,
    useCircles: true,
    popups: [],
    map: null,
    options: {
      container: "mapContainer",
      projection: "mercator" //equalEarth, globe, naturalEarth, mercator
    },

    pointLayerIds: {},
    hoverStatePointLayerIds: {},
    tooltipPointLayerIds: {},
    mobileClickTooltipPointLayerIds: {},
    geomClickLoadPopup: {},
    dataSouceIds: {},
    filterableDataSouceIds: {},
    comboIconDataSoucesIds: {},
    clusterLayersWithNoClick: {}
  }),
  mounted() {},
  created() {
    eventBus.$on("nav_exitFullScreen", () => {
      this.exitFullScreen();
    });

    eventBus.$on("nav_map_recenter", () => {
      this.reCenterMap();
    });

    eventBus.$on("nav_map_focus_on_trail", () => {
      this.focusMapOnDestinations();
    });
    eventBus.$on("nav_map_reset", () => {
      this.resetMap();
    });
  },
  watch: {
    trailDestinationSlugs: function() {
      this.updatefilters(this.layers);
      this.clearAllPopups();
    },

    layers: function(filters) {
      this.updatefilters(filters);
      this.clearAllPopups();
    }
  },
  methods: {
    reCenterMap() {
      let { pitch, center, bearing, zoom } = this.appSettings.map.defaultSetup;

      if (this.appSettings.map.flyToBounds) {
        let options = { bearing, pitch };
        if (this.drawer === true) {
          options.padding = { top: 0, bottom: 0, left: 0, right: 0 };
        }

        this.map.fitBounds(this.appSettings.map.flyToBounds, options);
      } else if (this.appSettings.map.defaultSetup) {
        this.map.flyTo({ pitch, center, bearing, zoom });
      }
    },
    resetMap(firstLoad) {
      let {
        pitch,
        center,
        bearing,
        zoom,
        zoomMob,
        zoomTab
      } = this.appSettings.map.defaultSetup;

      if (this.$vuetify.breakpoint.mobile) {
        if (zoomMob) {
          zoom = zoomMob;
        }
      } else if (this.$vuetify.breakpoint.smAndDown) {
        if (zoomTab) {
          zoom = zoomTab;
        }
      }

      if (firstLoad) {
        if (this.appSettings.map.bounds) {
          this.map.setMaxBounds(this.appSettings.map.bounds);
        }
      } else {
        window.mvAnalyticEvent("ui", "resetPressed", "resetPressed", false);
        this.$store.dispatch("filters_resetLayers");
        eventBus.$emit("remote-ui-layercontrol-close");
        eventBus.$emit("remote-ui-legned-close");
        this.clearAllPopups();
        this.$store.dispatch("navUpdateSidebarSite", false);
        this.$store.dispatch("navUpdateFocusSite", false);
        eventBus.$emit("nav_siteList_scrollToTop");
      }

      this.map.resize();

      setTimeout(() => {
        if (this.appSettings.map.flyToBounds) {
          let options = { bearing, pitch };
          if (this.drawer === true) {
            options.padding = this.$vuetify.breakpoint.smAndDown
              ? this.appSettings.boundFitDefaultMapPaddingMobile
              : this.appSettings.boundFitDefaultMapPadding;
          }

          this.map.fitBounds(this.appSettings.map.flyToBounds, options);
        } else if (this.appSettings.map.defaultSetup) {
          this.map.flyTo({ pitch, center, bearing, zoom });
        }
      }, 100);
    },

    exitFullScreen() {
      //if fullscreen mode go back to normal
      try {
        let [FullscreenControl] = this.map._controls.filter(
          value => value.constructor.name == "FullscreenControl"
        );
        if (FullscreenControl._fullscreen === true) {
          FullscreenControl._onClickFullscreen();
        }
      } catch (error) {
        //do nothing
      }
    },
    openSidebar(index) {
      this.exitFullScreen();

      eventBus.$emit("nav_layout_openMenu");
      eventBus.$emit("nav_sideBar_openMoreInfo");
      this.$store.dispatch("navUpdateSidebarSite", index);
    },

    loadMarkerByIdFromMenu(index) {
      this.clearAllPopups();

      try {
        let properties = this.sites[index].properties;
        let coordinates = this.sites[index].geometry.coordinates;

        this.createNormalPopup(coordinates, properties);

        //if (this.map.getZoom() < 18) {

        this.updatePaddingZoomForRemotePopup(
          coordinates,
          true,
          properties,
          "A"
        );
        //}
      } catch (error) {
        //do nothing;
      }
    },

    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[[]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    } /*
    updatePath(properties) {

      let newId = properties.Site_Marker_Number;
      //let oldId = this.$route;
      //if (newId && newId !== oldId) {
      if (newId) {
        var newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?id=${newId}`;

        window.history.pushState({ path: newurl }, "", newurl);
      }
    },
*/,
    initialized(map) {
      if (map) {
        //do nothing!
      }

      /*
      map.on("style.load", () => {
        map.setConfigProperty("basemap", "showPointOfInterestLabels", false);
        map.setConfigProperty("basemap", "showTransitLabels", false);
        map.setConfigProperty("basemap", "show3dObjects", true);
        map.setConfigProperty("basemap", "theme", "faded");
        //        map.setConfigProperty("basemap", "theme", "monochrome");
        map.setConfigProperty("basemap", "lightPreset", "day");
      });
      
      */
    },

    async loaded(map) {
      //https://docs.mapbox.com/mapbox-gl-js/guides/styles/

      window.clearAllPopups = this.clearAllPopups.bind(this);
      map._isVue = true;
      this.map = map;
      window.thismap = map;

      this.addShareButtons();

      if (this.appSettings.map.disableRotationAndBearing) {
        // disable map rotation using right click + drag
        this.map.dragRotate.disable();
        // disable map rotation using touch rotation gesture
        this.map.touchZoomRotate.disableRotation();
      }

      this.resetMap(true);

      /*
          maxBounds:
*/

      setTimeout(() => {
        /*
        customArrowWorkflow.addLayers({
          parentThis: this,
          map: this.map,
          appSettings: this.appSettings
        });
*/
        var clickableDiv = document.querySelector(".mapboxgl-ctrl-geolocate");

        // Step 2: Attach a click event listener to the element
        clickableDiv.addEventListener("click", function() {
          window.mvAnalyticEvent("ui", "geolocateClicked", "map");
        });
      }, 1000);

      if (tempGeoJsonLineGeomService && !this) {
        tempGeoJsonLineGeomService.addLayers({
          parentThis: this,
          map: this.map,
          appSettings: this.appSettings
        });
      }
      /*
      geoJsonLineGeomService.addLayers({
        parentThis: this,
        map: this.map,
        appSettings: this.appSettings
      });
*/
      try {
        this.currentZoom = map.getZoom();
        this.currentZoom = Math.round(this.currentZoom * 100) / 100;
      } catch (error) {
        //do nothing;
      }

      map.on("moveend", () => {
        this.moveEndCheckPopupPosition();

        try {
          this.currentZoom = map.getZoom();
          this.currentZoom = Math.round(this.currentZoom * 100) / 100;
        } catch (error) {
          //do nothing;
        }
      });

      /*
      if (this === false) {
        animationService.drawPlane({
          parentThis: this,
          map: this.map,
          appSettings: this.appSettings
        });
      }

      if (this === false) {
        animationService.drawBoat({
          parentThis: this,
          map: this.map,
          appSettings: this.appSettings
        });
      }
*/

      genericMapServices.initFalseImageObj(this.map);

      let loadartwork = true;

      if (loadartwork) {
        artworkAssetService.addArtWorkLayer({
          parentThis: this,
          map: this.map,
          geoJson_artwork: this.geoJson_artwork,
          categoryLookup: this.categoryLookup,
          appSettings: this.appSettings
        });
      }

      await markerService.createWpmarkerImages(
        this.map,
        this.markerImages,
        this.categoryLookup,
        this.appSettings.markerImageAssetOrderPriority
      );

      this.initMapTrailLineGeom(this.map);
      this.initMapScreenQueryFunction();
      /*
      await signageMarkerLayerService.createSignageLayer({
        parentThis: this,
        map: this.map,
        layerIdName: "signage",
        geoJson: this.geoJson_signage,
        appSettings: this.appSettings,
        markerSections: this.markerSections
      });
*/
      await (this.appSettings?.layerDefaults?.places?.useCircle
        ? circleSignageMarkerLayerService
        : signageMarkerLayerService
      ).createSignageLayer({
        parentThis: this,
        map: this.map,
        layerIdName: "places",
        geoJson: this.markerList,
        appSettings: this.appSettings,
        markerSections: this.markerSections
      });

      mapEventsService.addEvents({
        parentThis: this,
        map: this.map,
        appSettings: this.appSettings,
        mapboxgl: window.mapboxgl,
        markerImages: this.markerImages
      });

      let queryStringTrail = this.getParameterByName("trail");

      let queryStringSiteNumber = this.getParameterByName("id");

      let validTargets = this.sites.filter(
        point => point.properties.Site_Marker_Number === queryStringSiteNumber
      );

      setTimeout(() => {
        if (validTargets[0]) {
          this.$store.dispatch("navUpdateFirstLoadTargetSite", validTargets[0]);
        }

        this.$store.dispatch("navUpdateSelectedTrail", queryStringTrail);
        this.$store.dispatch(
          "filters_controlsUpdate_trailSelect",
          queryStringTrail
        );

        setTimeout(() => {
          this.$store.dispatch("navUpdateIsLoading");

          /*
        //this is to load points from query tring on load; ;

        let queryStringSiteNumber = this.getParameterByName("id");

        let validTargets = this.sites.filter(
          point => point.properties.Site_Marker_Number === queryStringSiteNumber
        );

        if (validTargets[0]) {
          try {
            let coordinates = validTargets[0].geometry.coordinates.slice();
            let properties = validTargets[0].properties;

            if (coordinates && properties?.Site_Marker_Number) {
              //window.gtag("event", "intalTo_" + properties.Site_Marker_Number);
              window.mvAnalyticEvent(
                "load",
                "intalTo",
                properties.Site_Marker_Number,
                false
              );

              this.createPopup(coordinates, properties, false);
              this.$store.dispatch("navUpdateSidebarSite", properties.index);

              this.map.flyTo({
                zoom: this?.appSettings?.zoomToOnSelect
                  ? this.appSettings.zoomToOnSelect
                  : 16,
                center: coordinates
              });
            }
          } catch (error) {
            //do nothing
          }
        }
*/

          this.updatefilters(this.layers);
        }, 300);

        this.updatefilters(this.layers);
      }, 300);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.container > div > .mapWrapperWrapper {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100vw;
  max-width: 100vw !important;
  border: none;
}

#mapContainer {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#currentZoomDev {
  height: 28px;
  width: 54px;
  overflow: visible;
  z-index: 2;
  top: 214px;
  left: 10px;
  position: absolute;
  background: white;
  border: 2px solid #ff000052;
  border-radius: 5px;
  text-align: center;
  font-size: 17px;
}
</style>
