//import { _ } from "core-js";

const getters = {};
const actions = {
  filters_enableLayer({ state, commit }, layer) {
    let layers = JSON.parse(JSON.stringify(state.layers));

    if (layer && !layers.includes(layer)) {
      layers.push(layer);
      commit("UPDATE_LAYERS", layers);
    }
  },

  filters_controlsUpdateLayers({ commit }, value) {
    commit("UPDATE_LAYERS", value);
  },

  filters_updateData({ commit }, wpData) {
    const categoryLookup = wpData.categoryLookup;

    const categoryArray = _.values(categoryLookup);

    let bools = categoryArray.reduce(function(map, obj) {
      map[obj.key] = true;
      return map;
    }, {});

    commit("SET_selected", { bools: bools });

    var layers = _.values(categoryLookup)
      .filter(layer => layer.hidden_by_default != true)
      .map(layer => layer.key);

    commit("SET_defaultLayers", layers);
    //commit("SET_layers", _.keys(categoryLookup));
    commit("SET_layers", layers);

    commit("SET_setup", { bools: [...categoryArray] });

    commit("SET_trailSelectOptions", wpData.formatTrailLookup);
  },

  filters_controlsUpdate_trailSelect({ state, commit }, value) {
    if (
      value &&
      state.formatTrailLookup &&
      state.formatTrailLookup[value] &&
      state.formatTrailLookup[value].trailDestinationSlug
    ) {
      commit("UPDATE_TRAIL_SELECT", value);

      commit(
        "UPDATE_TRAIL_DESTINATION",
        state.formatTrailLookup[value].trailDestinationSlug
      );
    }
  },

  filters_resetLayers({ state, commit }) {
    commit("UPDATE_LAYERS", state.defaultLayers);
  }
};

const mutations = {
  UPDATE_LAYERS(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.layers));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.layers = JSON.parse(JSON.stringify(value));
    }
  },

  SET_selected(state, newValue) {
    state.selected = newValue;
  },
  SET_defaultLayers(state, newValue) {
    state.defaultLayers = newValue;
  },
  SET_layers(state, newValue) {
    state.layers = newValue;
  },
  SET_setup(state, newValue) {
    state.setup = newValue;
  },

  SET_trailSelectOptions(state, newValue) {
    state.formatTrailLookup = newValue;
    let arrayValue = Object.values(newValue);
    state.trailSelect = ""; //arrayValue[0].slug;
    state.defaultTrailSelect = ""; //arrayValue[0].slug;
    state.trailSelectOptions = arrayValue;
  },
  UPDATE_TRAIL_SELECT(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.trailSelect));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      //console.log("UPDATE_TRAIL_SELECT", value);
      state.trailSelect = JSON.parse(JSON.stringify(value));
    }
  },
  UPDATE_TRAIL_DESTINATION(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.trailDestinationSlugs));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.trailDestinationSlugs = JSON.parse(JSON.stringify(value));
    }
  }
};

import { _ } from "core-js";

const state = {
  selectable: {},
  selected: {
    bools: {}
    //{ "Prospective Families": true, Wayfinding: true, History: true }
  },

  defaultLayers: [], //["History", "Prospective Families", "Wayfinding"],
  layers: [], //["History", "Prospective Families", "Wayfinding"],
  setup: {
    bools: []
  },

  defaultTrailSelect: "",
  trailSelect: "",
  trailDestinationSlugs: [],
  trailSelectOptions: [],

  formatTrailLookup: {},

  mapStyleExtraFilters: {
    /*
    "basemapStyle_principal-bicycle-network-proposed": {
      id: 10001,
      key: "basemapStyle_principal-bicycle-network-proposed",
      count: 1,
      title: "Bike Lanes",
      name: "basemapStyle_principal-bicycle-network-proposed",
      section_slug: "basemapStyle_principal-bicycle-network-proposed",
      taxonomy: "basemapStyle",
      color: "red",
      section_type: "basemapStyle",
      visible: false,
      linkedToMakerLayer: "move"
    },
    "basemapStyle_principal-bicycle-network-pbn-62cjnt": {
      id: 10001,
      key: "basemapStyle_principal-bicycle-network-pbn-62cjnt",
      count: 1,
      title: "Bike Lanes",
      name: "basemapStyle_principal-bicycle-network-pbn-62cjnt",
      section_slug: "basemapStyle_principal-bicycle-network-pbn-62cjnt",
      taxonomy: "basemapStyle",
      color: "red",
      section_type: "basemapStyle",
      visible: false,
      linkedToMakerLayer: "move"
    },

    "basemapStyle_vic-o-transport-routes-bus-61o0ng": {
      id: 10002,
      key: "basemapStyle_vic-o-transport-routes-bus-61o0ng",
      count: 1,
      title: "BUS Lanes",
      name: "basemapStyle_vic-o-transport-routes-bus-61o0ng",
      section_slug: "basemapStyle_vic-o-transport-routes-bus-61o0ng",
      taxonomy: "basemapStyle",
      color: "red",
      section_type: "basemapStyle",
      visible: false,
      linkedToMakerLayer: "move"
    }
*/
    //these are used to link mapbox styles to the layer controls.
    /*
    //user format for object key, id, key as basemapStyle_${mapbox style layer id}
    "basemapStyle_principal-bicycle-network-pbn-01": {
      id: 10001,
      key: "basemapStyle_principal-bicycle-network-pbn-01",
      count: 1,
      title: "Bike Lanes",
      name: "basemapStyle_principal-bicycle-network-pbn-01",
      section_slug: "basemapStyle_principal-bicycle-network-pbn-01",
      taxonomy: "basemapStyle",
      color: "red",
      section_type: "basemapStyle",
      visible: true, 
      linkedToMakerLayer:"move"
    }, 
    
    "basemapStyle_vic-o-transport-routes-bus-01": {
      id: 10001,
      key: "basemapStyle_vic-o-transport-routes-bus-01",
      count: 1,
      title: "Bus Lines",
      name: "basemapStyle_vic-o-transport-routes-bus-01",
      section_slug: "basemapStyle_vic-o-transport-routes-bus-01",
      taxonomy: "basemapStyle",
      color: "blue",
      section_type: "basemapStyle",
      visible: true, 
      linkedToMakerLayer:"move"
    }*/
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
