<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.smAndDown
          ? 550
          : 650
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class=" " @click="scrollToSiteDelay()">
          FAQ
        </span>
      </template>

      <v-card>
        <v-card-title
          v-if="true"
          :style="{
            color: 'black',
            background: 'menuBarColor'
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          FAQs
        </v-card-title>

        <v-row no-gutters class="secondary--text " style="">
          <v-col
            cols="12"
            md="12"
            class="pt-3 px-4 imageWrapper"
            id="HelpActWrapper"
            @scroll="onScroll"
          >
            <mvWpContentBlock
              classname="faq"
              posttype="pages"
              slug="faq"
            ></mvWpContentBlock>

            <faqModal_gen :siteId="siteId" />
          </v-col>
        </v-row>

        <v-card-actions>
          <transition name="fade" mode="out-in">
            <v-btn
              v-show="!atTop"
              class="v-fade"
              depressed
              color="primary"
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>

          <v-spacer></v-spacer>

          <v-btn depressed color="primary" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import { mapState } from "vuex";
import faqModal_gen from "./faqModal_gen";

import mvWpContentBlock from "../../ui/mvWpContent/mvWpContentBlock.vue";

export default {
  name: "HelpModal",
  components: {
    faqModal_gen,
    mvWpContentBlock
  },
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Help"
    };
  },
  computed: {
    ...mapState({
      siteId: state => state.global.siteId,

      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadHelpDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `topOfHelpSection`;

      if (newValue) {
        element = `helpHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById("HelpActWrapper");
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  }
};
</script>

<style>
#topOfHelpSection img {
  max-width: 80%;
}
</style>
