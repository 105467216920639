var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-data-table',{staticClass:"reviewTableAct",attrs:{"headers":_vm.showContent
          ? [
              {
                text: 'Actions',
                value: 'actions',
                filterable: false,
                sortable: false
              },
              {
                text: 'edit',
                value: 'post_id',
                filterable: false,
                width: '100'
              },

              {
                text: 'Point Name',
                value: 'Point_Name',
                filterable: true,
                width: '200'
              },

              {
                text: 'Item Type',
                value: 'section_type',
                filterable: true
              },
              {
                text: 'Layer',
                value: 'section_slug',
                filterable: true
              },

              {
                text: 'Marker Type',
                value: 'marker_type',
                filterable: true
              },
              {
                text: 'icon_img',
                value: 'icon_obj',
                filterable: false,
                sortable: false
              },

              {
                text: 'content',
                value: 'content',
                filterable: false,
                sortable: false
              },

              {
                text: 'details',
                value: 'details',
                filterable: false,
                sortable: false
              },
              {
                text: 'Media',
                value: 'media',
                filterable: false,
                width: '1500'
              }
            ]
          : _vm.showThumbs
          ? [
              {
                text: 'Actions',
                value: 'actions',
                filterable: false,
                sortable: false
              },
              {
                text: 'edit',
                value: 'post_id',
                filterable: false,
                width: '100'
              },

              {
                text: 'Point Name',
                value: 'Point_Name',
                filterable: true,
                width: '200'
              },
              {
                text: 'Media',
                value: 'media',
                filterable: false
              }
            ]
          : [
              {
                text: 'Actions',
                value: 'actions',
                filterable: false,
                sortable: false
              },
              { text: 'edit', value: 'post_id', filterable: false },
              {
                text: 'Has Long Lat',
                value: 'hasCoordinates',
                filterable: false
              },
              {
                text: 'Has Text',
                value: 'hasContentText',
                filterable: false
              },
              {
                text: 'Has Address',
                value: 'hasAddress',
                filterable: false
              },
              {
                text: 'Marker Number',
                value: 'Site_Marker_Number',
                filterable: true
              },
              { text: 'Point Name', value: 'Point_Name', filterable: true },

              {
                text: 'Item Type',
                value: 'section_type',
                filterable: true
              },
              {
                text: 'Layer',
                value: 'section_slug',
                filterable: true
              },

              {
                text: 'Marker Type',
                value: 'marker_type',
                filterable: true
              },

              {
                text: 'Marker Image',
                value: 'icon_obj',
                filterable: false,
                sortable: false
              },
              /*
              {
                text: 'icon title',
                value: 'icon_obj.title',
                filterable: false
              },
              */
              {
                text: 'List Icon',
                value: 'list_image_obj',
                filterable: false,
                sortable: false
              },
              {
                text: 'Media',
                value: 'media',
                filterable: false
              }
            ],"items":_vm.markerListData,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': [_vm.itemsPerPage ].concat( _vm.itemPerPageOption)
      },"search":JSON.stringify({
          markerSearch: _vm.markerSearch,
          selectedSectionTypes: _vm.selectedSectionTypes,
          selectabledLayers: _vm.selectabledLayers
        }),"custom-filter":_vm.filterFunction},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('ItemDialog',{attrs:{"data":item}})]}},{key:"item.details",fn:function(ref){
        var item = ref.item;
return [_c('ItemDialog',{attrs:{"title":item.structuredDataCount,"data":item}})]}},{key:"top",fn:function(ref){
        var pagination = ref.pagination;
        var options = ref.options;
        var updateOptions = ref.updateOptions;
return [_c('v-form',{staticClass:"topForm"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-checkbox',{staticClass:"mb-0 pb-0",attrs:{"label":"Show Body Text"},on:{"click":function($event){_vm.showThumbs = false}},model:{value:(_vm.showContent),callback:function ($$v) {_vm.showContent=$$v},expression:"showContent"}}),_c('v-checkbox',{staticClass:"mb-0 pb-0",attrs:{"label":"Media Review"},on:{"click":function($event){_vm.showContent = false}},model:{value:(_vm.showThumbs),callback:function ($$v) {_vm.showThumbs=$$v},expression:"showThumbs"}}),_c('div',{staticClass:"mt-4 ml-6"},[_vm._v(" type: ")]),_c('v-select',{staticClass:"mx-4 mr-4",attrs:{"items":_vm.sectionTypes,"label":"Section Types","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedSectionTypes.length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedSectionTypes),callback:function ($$v) {_vm.selectedSectionTypes=$$v},expression:"selectedSectionTypes"}}),_c('div',{staticClass:"mt-4 ml-6"},[_vm._v(" layer: ")]),_c('v-select',{staticClass:"mx-4 mr-4",attrs:{"items":_vm.selectableLayers,"label":"Section Types","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectabledLayers.length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectabledLayers),callback:function ($$v) {_vm.selectabledLayers=$$v},expression:"selectabledLayers"}}),_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){_vm.selectabledLayers = []}}},[_vm._v(" clear layers")]),_c('v-text-field',{staticClass:"mx-4 mr-4",attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":""},model:{value:(_vm.markerSearch),callback:function ($$v) {_vm.markerSearch=$$v},expression:"markerSearch"}}),_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-options":[_vm.itemsPerPage ].concat( _vm.itemPerPageOption),"items-per-page-text":"Rows per page"},on:{"update:options":updateOptions}})],1)])]}},{key:"item.post_id",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('a',{attrs:{"href":("" + (_vm.appSettings.wpEditSite) + (item.post_id) + "&action=edit"),"target":"_blank"}},[_vm._v(" edit: "+_vm._s(item.post_id)+" ")])])]}},{key:"item.content",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('h3',[_vm._v(_vm._s(item.Point_Name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.content),expression:"item.content"}],staticClass:"pb-10",staticStyle:{"border":"1px solid black","width":"300px","margin-bottom":"10px","padding":"0 8px"},domProps:{"innerHTML":_vm._s(item.content)}})])]}},{key:"item.icon_obj",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"OmStatus"},[_c('v-avatar',{staticClass:"profile",attrs:{"size":_vm.iconSize,"tile":""}},[_c('v-img',{attrs:{"src":item.icon_obj.marker}})],1)],1)]}},{key:"item.list_image_obj",fn:function(ref){
        var item = ref.item;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            item.list_image_obj &&
              item.icon_obj &&
              item.list_image_obj.marker != item.icon_obj.marker
          ),expression:"\n            item.list_image_obj &&\n              item.icon_obj &&\n              item.list_image_obj.marker != item.icon_obj.marker\n          "}],staticClass:"OmStatus"},[_c('v-avatar',{staticClass:"profile",attrs:{"size":_vm.iconSize,"tile":""}},[_c('v-img',{attrs:{"src":item.list_image_obj.marker}})],1)],1)]}},{key:"item.media",fn:function(ref){
          var item = ref.item;
return [_c('div',[(_vm.showThumbs)?_c('div',{staticClass:"reviewMediaWrapper d-flex flex-row  flex-wrap"},_vm._l((item.media),function(mediaItem,index){return _c('div',{key:((item.post_id) + "_media_" + index),staticStyle:{"border":"1px solid #aaa","width":"466px","margin":"2px","padding":"8px"}},[_c('h1',[_vm._v(_vm._s(mediaItem.title))]),_c('a',{attrs:{"href":("" + (_vm.appSettings.wpEditSite) + (mediaItem.ID) + "&action=edit"),"target":"_blank"}},[_vm._v(" edit media item: "+_vm._s(mediaItem.ID)+" ")]),_c('div',[_vm._v(" mv_vertical_focus: "+_vm._s(mediaItem.mv_vertical_focus ? mediaItem.mv_vertical_focus : "unset")+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(mediaItem.mv_horizontal_focus),expression:"mediaItem.mv_horizontal_focus"}]},[_vm._v(" mv_horizontal_focus: "+_vm._s(mediaItem.mv_horizontal_focus)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(mediaItem.aspectRatiosClass),expression:"mediaItem.aspectRatiosClass"}]},[_vm._v(" aspectRatiosClass: "+_vm._s(mediaItem.aspectRatiosClass)+" ")]),_c('br'),_c('h3',[_vm._v("Thumbnail:")]),_c('img',{attrs:{"width":200,"title":("" + (JSON.stringify({
                    title: mediaItem.title,
                    slug: mediaItem.slug,
                    caption: mediaItem.caption
                  }))),"src":mediaItem.src_thumb}}),_c('v-img',{attrs:{"src":mediaItem.src_thumb}}),_c('br'),_c('div',{class:{
                  focusTop: mediaItem.mv_vertical_focus == 'top',
                  focusBottom: mediaItem.mv_vertical_focus == 'bottom',
                  focusTopCenter: mediaItem.mv_vertical_focus == 'top_center',
                  focusBottomCenter:
                    mediaItem.mv_vertical_focus == 'bottom_center',
                  focusLeft: mediaItem.mv_horizontal_focus == 'left',
                  focusRight: mediaItem.mv_horizontal_focus == 'right',
                  tallAspectImage:
                    mediaItem.aspectRatiosClass == 'tallAspectImage',
                  mediumTallAspectImage:
                    mediaItem.aspectRatiosClass == 'mediumTallAspectImage'
                }},[_c('h3',[_vm._v("Side More Info Image:")]),_c('div',{staticStyle:{"border":"1px solid grey","width":"450px"}},[_c('v-img',{attrs:{"height":'190px',"width":'450px',"src":mediaItem.src_thumb,"eager":""}})],1),_c('h3',[_vm._v("Popup Image:")]),_c('div',{staticStyle:{"border":"1px solid grey","width":"285px"}},[_c('v-img',{attrs:{"height":'150px',"width":'285px',"src":mediaItem.src_thumb,"eager":""}})],1)]),(
                  mediaItem.aspectRatiosClass == 'tallAspectImage' ||
                    mediaItem.aspectRatiosClass == 'mediumTallAspectImage'
                )?_c('div',{staticClass:"enableAltAspectRation"},[_c('h3',[_vm._v("Side More Tall Letterbox Image: :")]),_c('div',{class:{
                    focusTop: mediaItem.mv_vertical_focus == 'top',
                    focusBottom: mediaItem.mv_vertical_focus == 'bottom',
                    focusTopCenter:
                      mediaItem.mv_vertical_focus == 'top_center',
                    focusBottomCenter:
                      mediaItem.mv_vertical_focus == 'bottom_center',
                    focusLeft: mediaItem.mv_horizontal_focus == 'left',
                    focusRight: mediaItem.mv_horizontal_focus == 'right',
                    tallAspectImage:
                      mediaItem.aspectRatiosClass == 'tallAspectImage',
                    mediumTallAspectImage:
                      mediaItem.aspectRatiosClass == 'mediumTallAspectImage'
                  }},[_c('div',{staticStyle:{"border":"1px solid grey","width":"450px"}},[_c('v-img',{attrs:{"height":'190px',"width":'450px',"src":mediaItem.src_thumb,"eager":""}})],1),_c('br')])]):_vm._e(),_c('br'),_c('hr')],1)}),0):_vm._e(),(!_vm.showThumbs)?_c('div',_vm._l((item.media),function(mediaItem,index){return _c('a',{key:((item.post_id) + "_media_" + index),staticStyle:{"padding":"2px"},attrs:{"href":mediaItem.src_gallery,"target":"_blank"}},[_c('v-avatar',{staticClass:"profile",staticStyle:{"border":"1px solid black"},attrs:{"size":_vm.iconSize,"tile":""}},[_c('v-img',{attrs:{"title":("" + (JSON.stringify({
                      title: mediaItem.title,
                      slug: mediaItem.slug,
                      caption: mediaItem.caption
                    }))),"src":mediaItem.size.mv_icon_thumb[0]}})],1)],1)}),0):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }