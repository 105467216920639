import { render, staticRenderFns } from "./allMapItemsTable.vue?vue&type=template&id=244482e1&"
import script from "./allMapItemsTable.vue?vue&type=script&lang=js&"
export * from "./allMapItemsTable.vue?vue&type=script&lang=js&"
import style0 from "./allMapItemsTable.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VCard,VCheckbox,VChip,VDataFooter,VDataTable,VForm,VImg,VSelect,VTextField})
