const merge = function(row, linkedItem) {
  let currentProps = row.properties;
  let cloneWithProps = JSON.parse(JSON.stringify(linkedItem.properties));

  let {
    //structuredData,
    media,
    content,
    icon_obj,
    icon_id,
    list_image_obj,
    list_image_id,
    galleryHasMobileWidth,
    galleryHasComputerWidth,
    hide
  } = cloneWithProps;

  try {
    let updateTitleKeys = [
      "name",
      "Point_Name",
      "mv_tooltip",
      "mv_label",
      "name"
    ];

    var splitword = "copy";
    for (const [key, value] of Object.entries(currentProps)) {
      if (updateTitleKeys.includes(key) && value && value.includes(splitword)) {
        try {
          currentProps[key] = value.split(splitword)[0];
          try {
            currentProps[key] = currentProps[key].trim();
          } catch (error) {
            //do nothing;
          }
        } catch (error) {
          //do nothing;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  try {
    let M_ST_Site_ID = currentProps?.structuredData?.subtitle?.M_ST_Site_ID;
    let cloneStructuredData = cloneWithProps?.structuredData;
    currentProps.structuredData = cloneStructuredData;
    if (currentProps?.structuredData?.subtitle?.M_ST_Site_ID) {
      currentProps.structuredData.subtitle.M_ST_Site_ID = M_ST_Site_ID;
    }
  } catch (error) {
    //do nothing;
  }

  try {
    currentProps = {
      ...currentProps,
      ...{
        media,
        content,
        icon_obj,
        icon_id,
        list_image_obj,
        list_image_id,
        galleryHasMobileWidth,
        galleryHasComputerWidth,
        hide
      }
    };
  } catch (error) {
    //do nothing;
  }

  row.properties = currentProps;

  return row;
};

module.exports.merge = merge;
