export default {
  data: () => ({}),

  computed: {},

  methods: {
    forceMapSize() {
      let height = this.$refs.mapWrapper.clientHeight;

      if (height !== this.mvGetScreenHightObj().height) {
        if (this.$vuetify.breakpoint.mdAndDown) {
          this.$refs.mapWrapper.style.height = `${
            this.mvGetScreenHightObj().height
          }px`;
        } else {
          this.$refs.mapWrapper.style.height = ``;
        }
        if (this.map) {
          this.map.resize();
        }
      }
    }
  },
  created() {
    window.addEventListener("resize", this.forceMapSize);

    setTimeout(() => {
      this.forceMapSize();
    }, 1500);
    setTimeout(() => {
      this.forceMapSize();
    }, 100);

    setInterval(() => {
      this.forceMapSize();
    }, 10000);
  },
  destroyed() {},
  mounted() {}
};
