<template>
  <div
    v-if="ready"
    class=" mapboxgl-ctrl mapboxgl-ctrl-group mv-share-button"
    style="height: 29px!important;
    width: 29px!important;
    min-width: auto!important;
    min-height: auto!important;
    padding: 0!important;
    margin: 0!important;
    
    "
  >
    <button
      style="height: 29px!important;
    width: 29px!important;
    min-width: auto!important;
    min-height: auto!important;
    
    "
      class="mapElementButton mapboxControlButton"
      @click="open()"
    >
      <v-icon dark default color="rgb(51 51 51)">
        mdi-share-variant
      </v-icon>
    </button>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import Vue from "vue";

import { eventBus } from "../../../main";

export default Vue.extend({
  name: "ShareButton",
  data: () => ({ ready: false }),

  props: {
    appSettings: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  mounted() {
    this.$vuetify = this.vuetifyCopy;
    this.$browserDetect = this.browserDetectCopy;
    this.ready = true;
  },
  methods: {
    open() {
      eventBus.$emit("openShareDialog");
    }
  }
});
</script>

<style scoped>
.mapboxControlButton {
  height: 29px !important;
}

.mapboxControlButton .v-btn__content .v-icon.v-icon--left {
  font-size: 24px;
}

.mv-share-button {
  display: none;
}
.trailIsSelect .mv-share-button {
  display: initial;
}
</style>
