const trail001_coastToGold = require("./trailGeoms/trail001_coastToGold.json");
const trail002_capitalToCoast = require("./trailGeoms/trail002_capitalToCoast.json");
const trail003_throughTheHeartOfAustralia = require("./trailGeoms/trail003_throughTheHeartOfAustralia.json");

let trailGeoms = [
  {
    slug: "goldfields-to-great-ocean-road",
    title: "Goldfields to Great Ocean Road",
    geoJson: trail001_coastToGold
  },
  {
    slug: "capital-to-coast",
    title: "Capital to Coast",
    geoJson: trail002_capitalToCoast
  },
  {
    slug: "through-the-heart-of-australia",
    title: "Through the Heart of Australia",
    geoJson: trail003_throughTheHeartOfAustralia
  }
];

let geoJsonFeatureCollection = {
  type: "FeatureCollection",
  features: []
};

trailGeoms.forEach(row => {
  let { geoJson, slug, title } = row;
  try {
    let [feature] = geoJson.features;

    if (feature && feature.properties) {
      feature.properties = { slug, title, active: false };
    }

    geoJsonFeatureCollection.features.push(feature);
  } catch (error) {
    //console.error(error);
  }
});

export default new (class {
  async getTrailGeom() {
    return geoJsonFeatureCollection;
  }
})();
