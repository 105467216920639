<template>
  <v-toolbar-title class="mvTitleBar" :title="`${siteObject.title} Maptivate`">
    <div class="inline d-flex align-center">
      <img
        title="Quest Apartment Hotels"
        v-if="appSettings.titleImageLogo === true"
        class="titleLogo "
        :src="`./assets/logos/quest_color.png`"
      />
    </div>
    <div class="inline d-flex align-center">
      <div
        v-if="
          !$vuetify.breakpoint.xsOnly || !appSettings.titleImageLogo === true
        "
        :class="
          `${
            appSettings.titleTextColorClass
              ? appSettings.titleTextColorClass
              : ''
          }`
        "
      >
        <font
          class="font-weight-medium"
          v-if="!appSettings.titleImageLogo === true"
          >{{ siteObject.title }}</font
        >
        <font
          class="pl-1 font-weight-medium"
          v-if="false && !$vuetify.breakpoint.xsOnly"
          >Maptivate</font
        >
      </div>
    </div>
  </v-toolbar-title>
</template>

<script>
export default {
  name: "mvToolbarTitle",

  props: {
    siteObject: Object,
    appSettings: Object
  }
};
</script>
<style>
.mvTitleBar img {
  padding: 2px;

  xxmax-height: 64px;
}

.mvTitleBar {
  max-height: 64px;
}

.mvTitleBar img.titleLogo {
  height: 55px;
  padding: 0;
}

.inline {
  float: left;
  height: 64px;
  xbackground: red;
  vertical-align: center;
}

.isMobile .mvTitleBar img.titleLogo {
  height: 35px;
  padding: 0;
}
</style>
