<template>
  <div class="trailSelectOptions d-flex flex-row notranslate" translate="no">
    <div class="custiconsWrapper ">
      <v-icon style="font-size: 15px;z-index: 5;" class="custicons"
        >mdi-chart-timeline-variant</v-icon
      >

      <v-icon style="font-size: 25px;z-index: 2;" class="custicons"
        >mdi-map</v-icon
      >
    </div>

    <v-select
      class="notranslate"
      solo
      :items="trailSelectOptions"
      item-text="Point_Name"
      item-value="slug"
      :label="$vuetify.breakpoint.smAndDown ? '' : 'Select A Road Trip'"
      v-model="trailSelect"
      hide-details
      xprepend-icon="mdi-map"
      xxprepend-icon=" mdi-map-outline  mdi-chart-timeline-variant"
      single-line
      x-small
    ></v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "mvTrailSelector",

  props: {},
  data() {
    return {
      selectedPoint: null
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      trailSelectOptions: state => state.filters.trailSelectOptions,
      trailDestinationSlugs: state => state.filters.trailDestinationSlugs
    }),

    trailSelect: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.trailSelect;
      },
      set(value) {
        this.$store.dispatch("navUpdateSelectedTrail", value);
        this.$store.dispatch("filters_controlsUpdate_trailSelect", value);
      }
    }
  }
};
</script>
<style lang="scss">
.xtrailSelectOptions .v-text-field__details {
  display: none;
}

.trailSelectOptionsIcons .custiconsWrapper {
  position: relative;
  display: inline-block;
  width: 24px; /* Adjust based on icon size */
  height: 24px;

  .custicons {
    margin-top: 0;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.trailSelectOptions {
  .custiconsWrapper {
    position: relative;
    display: inline-block;
    width: 24px; /* Adjust based on icon size */
    height: 24px;
  }

  .custicons {
    margin-top: 7px;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 0;
    left: 0;
  }

  i.custicons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .v-select > .v-input__prepend-outer {
    margin-top: 7px;
  }

  .v-select,
  .v-select > .v-input__control {
    height: 36px;
    min-height: 36px;
  }

  .v-input__control > .v-input__slot {
    width: 290px;
  }

  .v-select__selection {
    max-width: 97%;
  }

  input {
    display: none;
  }

  .custiconsWrapper {
    margin-right: 8px;
  }
}

.isMobile {
  .trailSelectOptions {
    .custiconsWrapper {
      margin-right: -34px;
    }
    .v-input__control > .v-input__slot {
      width: 65px;

      .v-select__selection {
        max-width: 0%;
      }
    }
  }
}
</style>
