<template>
  <center>
    <v-card-title style="display:initial"> Wominjeka </v-card-title>

    <v-card-text>
      Zoos Victoria acknowledges and respects the Traditional Owners as the
      original custodians of the land and waters, their ability to care for
      Country and their deep spiritual connection to it. We honour Elders past
      and present whose knowledge and wisdom has ensured the continuation of
      culture and traditional practices.
    </v-card-text>

    <v-card-title style="display:initial"
      >Explore the zoo using our map
    </v-card-title>
    <v-card-text>
      Learn how to use the map here
      <v-btn x-small depressed color="primary" @click="nav_faq()"> FAQ</v-btn>

      <ToggleGeoButton :isReady="isReady" :dialog="dialog"></ToggleGeoButton>

      <br />
      You can also use this button to change the location settings:
    </v-card-text>
    <v-img
      style="max-width: 150px;"
      :src="`/assets/intro/${siteId}_location.png`"
    ></v-img>
  </center>
</template>

<script>
import { eventBus } from "../../../main";

import { mapState } from "vuex";
import ToggleGeoButton from "../../ui/ToggleGeoButton.vue";

export default {
  name: "WelcomeDialog_gen",
  components: {
    ToggleGeoButton
  },
  data() {
    return {};
  },
  props: {
    dialog: Boolean,
    isReady: Boolean
  },
  computed: {
    ...mapState({
      siteId: state => state.global.siteId,
      siteObject: state => state.global.siteObject,
      appSettings: state => state.global.appSettings,
      imageLog: state => state.markers.imageLog
    })
  },

  methods: {
    nav_faq() {
      eventBus.$emit("loadHelpDialog");
    }
  },
  mounted() {}
};
</script>

<style></style>
