import { mapState } from "vuex";
import { eventBus } from "../../../main";

import PopupStackedMenu from "./../../mapelements/PopupStackedMenu.vue";
import PopupContent from "./../../mapelements/PopupContent.vue";
//import AnimatedPopup from "mapbox-gl-animated-popup";

export default {
  data: () => ({}),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings,

      isDevEnviroment: state => state.navigation.isDevEnviroment,
      siteObject: state => state.global.siteObject
    })
  },

  methods: {
    getOffest() {
      return 1;
      /*let factor = 0.8;
      if (this.lastMarkerSize > 1) {
        return 50 * factor;
      } else if (this.lastMarkerSize > 0.8) {
        return 35 * factor;
      } else if (this.lastMarkerSize > 0.6) {
        return 25 * factor;
      } else if (this.lastMarkerSize > 0.4) {
        return 20 * factor;
      } else if (this.lastMarkerSize > 0.2) {
        return 10 * factor;
      }
      return 5 * factor; //imageSize * 100;*/
    },

    //loadActPopups;
    createNormalPopup(coordinates, properties) {
      if (
        (properties && properties?.hide) ||
        (!coordinates[0] && !coordinates[1])
      ) {
        //do nothing!!
        //console.log("target hidden");
      } else {
        try {
          if (this.sites[properties.index]?.properties) {
            properties = this.sites[properties.index]?.properties;
          }
        } catch (error) {
          //do nothing
        }

        let newPopup = new window.mapboxgl.Popup({
          /*  let newPopup = new AnimatedPopup({
        openingAnimation: {
          duration: 900,
          easing: "easeOutElastic",
          //easeOutQuint
          //easeInOutExpo
          //easeOutElastic
          transform: "scale"
        },
        closingAnimation: {
          duration: 150,
          easing: "easeInBack",
          transform: "scale"
        },
*/

          className: "galPopup mvPopupAll",
          //closeOnMove: true
          closeButton: false
          //offset: 25
          //anchor: "top"
          //offset: 5,
        })
          .setLngLat(coordinates)
          .setHTML('<div id="vue-popup-content"></div>')
          //.setOffset(this.getOffest())
          .addTo(this.map);

        this.popups.push(newPopup);

        try {
          new PopupContent({
            propsData: {
              isDevEnviroment: this.isDevEnviroment,
              editPost: this.siteObject.editPost,

              properties: properties,
              clearAllPopups: this.clearAllPopups,
              getNavTabState: this.getNavTabState,
              vuetifyCopy: this.$vuetify,
              browserDetectCopy: this.$browserDetect,
              openSidebar: this.openSidebar,
              setSound: this.setSound,
              openYoutube: this.openYoutube,
              getAudioDetails: this.getAudioDetails,
              goToPointByPointName: this.goToPointByPointName,
              appSettings: this.appSettings,
              pointSet: {
                geoJson_artwork: this.geoJson_artwork,
                geoJson_signage: this.geoJson_signage,
                markerList: this.markerList
              }
            }
          }).$mount("#vue-popup-content");
        } catch (error) {
          //do nothink;
        }

        setTimeout(() => {
          //this.map.resize();
          this.map.fire("move");
          //this.map.triggerRepaint();
        }, 1);

        let _selfStore = this.$store;

        newPopup.on("close", function(/*e*/) {
          _selfStore.dispatch("navUpdateSidebarSite", false);
          eventBus.$emit("nav_sideBar_openSiteList");
        });

        //this.updatePath(properties);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
        //window.gtag("event", "popupOpen", properties.Site_Marker_Number);
        window.mvAnalyticEvent(
          "map",
          "popupOpen",
          properties.Site_Marker_Number,
          false
        );
      }
    },
    createStackedMenuPopup(coordinates, mappedMenuItems) {
      let newPopup = new window.mapboxgl.Popup({
        className: "galMenu  mvPopupAll",
        closeButton: false
      })
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .setOffset(this.getOffest())
        .addTo(this.map);

      this.popups.push(newPopup);
      new PopupStackedMenu({
        propsData: {
          mappedMenuItems,
          loadMarkerByIdFromMenu: this.loadMarkerByIdFromMenu,
          vuetifyCopy: this.$vuetify,
          appSettings: this.appSettings,
          categoryLookup: this.categoryLookup
        }
      }).$mount("#vue-popup-content");
    },
    //sharedFunctions;
    clearAllPopups() {
      this.clearTooltip();
      setTimeout(() => {
        this.clearTooltip();
      }, 30);

      eventBus.$emit("nav_sideBar_openSiteList");
      this.popups.forEach(popup => {
        popup.remove();
      });
      this.popups = [];
    },

    clearAllPopupsBasic() {
      try {
        const elements = document.getElementsByClassName("galPopup");
        while (elements.length > 0) {
          elements.forEach(element => {
            element.remove();
          });
        }
      } catch (error) {
        //do nothing
      }
    },

    //initalEventMiddleFunctions;

    customMarkerClickEvent({ coordinates, marker_indexs }) {
      this.clearAllPopups();

      let mappedMenuItems = [];
      marker_indexs = [...new Set(marker_indexs)];
      marker_indexs.forEach(index => {
        mappedMenuItems.push(this.sites[index].properties);
      });

      this.createStackedMenuPopup(coordinates, mappedMenuItems);
    },

    createPopupFromIndex(index) {
      try {
        let properties = this.sites[index].properties;
        let coordinates = this.sites[index].geometry.coordinates;
        this.updatePaddingZoomForRemotePopup(
          coordinates,
          true,
          properties,
          "Z"
        );

        this.createPopup(coordinates, properties);
      } catch (error) {
        //error loacing popup
      }
    },

    createPopup(coordinates, properties, allowStacking) {
      try {
        properties = this.sites[properties.index].properties;
      } catch (error) {
        //do nothing
      }
      //markerList

      this.clearAllPopups();
      if (
        allowStacking &&
        properties.hasStacking &&
        this.stackedMarkers[properties.geoIndex]
      ) {
        let stackMenu = this.stackedMarkers[properties.geoIndex];
        let mappedMenuItems = stackMenu.map(
          item => this.sites[item].properties
        );

        this.createStackedMenuPopup(coordinates, mappedMenuItems);
      } else {
        this.createNormalPopup(coordinates, properties);
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
