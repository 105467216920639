<template>
  <v-card elevation="4" v-if="ready" class="stackedMenu">
    <v-card-title
      :class="appSettings.headingTextColor"
      v-if="false && mappedMenuItemsFilter.length"
      >has items</v-card-title
    >
    <v-list
      class="pl-0"
      dense
      :max-height="$vuetify.breakpoint.mobile ? '350px' : '400px'"
      :class="$vuetify.breakpoint.mobile ? 'custScroller' : 'custScroller'"
      style="overflow-y:auto"
    >
      <template v-for="(item, itemIndex) in mappedMenuItemsFilter">
        <v-list-item
          class="pl-2 pr-0"
          :key="item.index"
          @click="
            loadMarkerByIdFromMenu(item.index);
            popupClicked(item);
          "
        >
          <v-list-item-content>
            <v-list-item-title
              class="grey--text font-weight-normal  d-flex flex-row"
              style="    line-height: 1.5rem;"
            >
              <div
                class="pa-0"
                :style="{
                  height: '24px',
                  width: '24px'
                }"
              >
                <img
                  v-if="item && item.icon_obj && item.icon_obj.marker_vs"
                  :src="item.icon_obj.marker_vs"
                  style="
              
                  display: block;
                  max-width: 100%;
                  width: 24px;
                  height: auto;"
                />
              </div>
              <div class="ml-2 listTextLabel">
                {{ item.Point_Name }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="item.header"
          v-if="mappedMenuItemsFilter.length - 1 != itemIndex"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    ready: false,
    showCarousel: false
  }),

  props: {
    getAudioDetails: Function,
    loadMarkerByIdFromMenu: Function,
    appSettings: Object,
    categoryLookup: Object,
    mappedMenuItems: {
      required: true,
      type: Array
    },
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    mappedMenuItemsFilter() {
      //console.log(this.mappedMenuItems);
      try {
        let items = this.mappedMenuItems.map(item => {
          try {
            item.icon_obj = JSON.parse(item.icon_obj);
          } catch (error) {
            //do nothing;
          }

          return item;
        });

        items = [
          ...items.filter(item => item.marker_type == "destination"),
          ...items.filter(item => item.marker_type == "quest-hotel"),
          ...items
            .filter(
              item =>
                item.marker_type != "quest-hotel" &&
                item.marker_type != "destination"
            )
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
        ];

        // console.log(items);

        return items;
      } catch (error) {
        return this.mappedMenuItems;
      }
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    setTimeout(() => {
      this.ready = true;
      this.showCarousel = true;
    }, 10);
  },
  methods: {
    popupClicked(properties) {
      //window.gtag("event", "markerClick", properties.Site_Marker_Number);
      window.mvAnalyticEvent(
        "map",
        "markerClick",
        properties.Site_Marker_Number,
        false
      );
    }
  }
});
</script>

<style>
.mapboxgl-popup-content {
  box-shadow: none;
}
.galMenu,
.galMenu .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galMenu {
  height: 90px;
}
.galMenu .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
}

.custScroller::-webkit-scrollbar {
  width: 1em;
}

.custScroller::-webkit-scrollbar-track {
  xbox-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #f1f1f1;
}

.custScroller::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: 1px solid #a1a1a1;
}

.pointPopupTooltip {
  margin-top: 10px;
}
.pointPopupTooltip .mapboxgl-popup-content {
  border-radius: 4px;
  padding: 2px 4px;
  text-align: center;
}

.pointPopupTooltip .mapboxgl-popup-tip {
  display: none;
}

.stackedMenu .v-list-item__title,
.stackedMenu .v-list-item__subtitle {
  flex: 1 1 100%;
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
}

.listTextLabel {
  width: 194px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
