<template>
  <div v-if="trailSelect">
    <v-list class="ma-0 pa-0" v-if="true">
      <v-list-item v-if="!bookingLink">
        <v-list-item-avatar size="50">
          <v-img src="assets/landingPages/acknowledgement.jpeg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="
            text-header text-wrap font-weight-medium font-weight-normal secondary--text
            "
            v-html="`Road Trip: ${trailTitle}`"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click.prevent="openUrl()" v-else>
        <v-list-item-avatar size="50">
          <v-img src="assets/landingPages/acknowledgement.jpeg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="
            text-header text-wrap font-weight-medium font-weight-normal secondary--text
            "
            v-html="`Book: ${trailTitle}`"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

//import SignageList from "./../info/SignageList.vue";

export default {
  props: {
    isOpen: Boolean,
    parentDrawer: Boolean
  },
  components: {},
  name: "TrailTitleItem",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      formatTrailLookup: state => state.filters.formatTrailLookup,
      trailSelect: state => state.filters.trailSelect
    }),

    trail() {
      return this.formatTrailLookup[this.trailSelect];
    },
    trailTitle() {
      return this.trail?.title;
    },
    bookingLink() {
      return this.trail?.acf?.M_L_QUEST_BOOKING_LINK?.value?.url;
    }
  },
  methods: {
    openUrl() {
      if (this.bookingLink?.includes("http")) {
        window.open(this.bookingLink, "_blank"); // Opens the URL in a new tab/window
      }
    }
  },
  created() {},

  watch: {}
};
</script>
